import {FC, memo} from 'react';
import {useStyle} from '../../utils/theme/useStyle';
import {InfoCardRules} from './InfoCard.style';
import Text from '../../components/UI/Text/Text';
import {Icon, IconList} from '../UI/Icon/Icon';

interface IProps {
  icon: IconList,
  title: string,
  text: string,
  iconBlockColor: string,
}

export const InfoCard: FC<IProps> = memo(function InfoCard(props) {
  const { icon, title, text, iconBlockColor } = props;
  const { css } = useStyle(InfoCardRules, {
    iconBlockColor
  });

  return (
    <div className={css.root}>
      <div className={css.iconBlock}>
        <Icon
          name={icon}
        />
      </div>
      <div className={css.textWrapper}>
        <Text
          text={title}
          mod='title'
          fontSize={16}
          fontWeight={700}
          lineHeight={18}
        />
        <Text
          mod='text'
          text={text}
          fontWeight={400}
          fontSize={14}
          lineHeight={18}
        />
      </div>
    </div>
  )
})

import {IRuleFn} from '../../utils/theme/fela.interface';
import {createCachedStyle} from '../../utils/theme/createCachedStyle';
import {padding} from '../../utils/applyCSSProperty ';

export interface IBannerWbOzonKeys {
  root: {};
  button: {}
  title: {};
  text: {};
  wb: {};
  ozon: {};
  closeButton: {};
}

export interface IBannerWbOzonProps {
  extend?: Partial<IBannerWbOzonKeys>;
}

export type IBannerWbOzon = IRuleFn<IBannerWbOzonKeys, IBannerWbOzonProps >;

const BannerWbOzonRuleFn: IBannerWbOzon = props => {
  const { colorWhite } = props.theme;

  return {
    root: {
      overflow: 'hidden',
      position: 'relative',
      borderRadius: 12,
      ...padding(57, 20, 20),
      background: 'radial-gradient(circle, rgba(1,152,233,1) 50%, rgba(1,132,255,1) 100%)',
    },
    button: {
      backdropFilter: 'blur(20px)',
      blur: 20,
    },
    title: {
      color: colorWhite,
      marginBottom: 16,
    },
    text: {
      color: colorWhite,
      marginBottom: 16,
    },
    wb: {
      borderRadius: '50%',
      boxShadow: '42px 77px 73px 0px rgba(0,0,0,0.25)',
      position: 'absolute',
      right: -12,
      top: -15,
      zIndex: 0
    },
    ozon: {
      borderRadius: '50%',
      boxShadow: '42px 77px 73px 0px rgba(0,0,0,0.25)',
      position: 'absolute',
      right: 66,
      top: -15,
      zIndex: 0
    },
    closeButton: {
      cursor: 'pointer',
      width: 50,
      height: 50,
      background: 'none',
      outline: 'none',
      border: 'none',
      position: 'absolute',
      zIndex: 1,
      top: -9,
      right: -11,
    }
  };
};

export const BannerWbOzonRules = createCachedStyle(BannerWbOzonRuleFn);

import {FC, memo, useRef,} from 'react';
import {useScrollModal} from '../../hooks/useScrollModal';
import {useStyle} from '../../utils/theme/useStyle';
import Modal, {ModalNames} from '../Modal/Modal';
import Text from '../UI/Text/Text';
import {ModalChooseIntegrationServiceRules} from './ModalChooseIntegrationService.style';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import {CardInfoBtn} from '../CardInfoBtn/CardInfoBtn';
import {useAppSelector} from '../../hooks/redux';
import {togglesState} from '../../redux/slices/configSlice';

interface IProps {
  active: boolean;
  onClose: () => void;
  openWbModal: () => void;
  openOzonModal: () => void;
  openBitrixModal: () => void;
}

export const ModalChooseIntegrationService: FC<IProps> = memo(function ModalChooseIntegrationService(props) {
  const { active, onClose, openOzonModal, openBitrixModal, openWbModal } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { wbIntegrationEnabled, ozonIntegrationEnabled, bitrixIntegrationEnabled } = useAppSelector(togglesState)
  // scroll modal on focus
  useScrollModal(modalInnerRef);

  const { css, rules } = useStyle(ModalChooseIntegrationServiceRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  const createOpenModalFn = callback => {
    return () => {
      onClose();
      callback();
    }
  }

  const openWb = createOpenModalFn(openWbModal);
  const openOzon = createOpenModalFn(openOzonModal);
  const openBitrix = createOpenModalFn(openBitrixModal);


  return (
    <Modal
      name={ModalNames.SettingStory}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalChooseIntegrationService.chooseService')} mod="title" />
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        <div className={css.cardWrapper}>
          {
            wbIntegrationEnabled
            && <CardInfoBtn
              title={t('modalChooseIntegrationService.wb')}
              text={t('modalChooseIntegrationService.connectWbAndWeWillUploadProductsToYourAppWhenProductsAreUpdatedOnWbTheyWillAlsoBeUpdatedHere')}
              isActive={false}
              hasInfo={false}
              extend={{
                title: rules.cardTitle
              }}
              onClick={openWb}
            />
          }
          {
            ozonIntegrationEnabled
            && <CardInfoBtn
              title={t('modalChooseIntegrationService.ozon')}
              text={t('modalChooseIntegrationService.connectOzonAndWeWillUploadProductsToYourAppWhenProductsAreUpdatedOnOzonTheyWillAlsoBeUpdatedHere')}
              isActive={false}
              hasInfo={false}
              extend={{
                title: rules.cardTitle
              }}
              onClick={openOzon}
            />
          }
          {
            bitrixIntegrationEnabled
            && <CardInfoBtn
              title={t('modalChooseIntegrationService.bitrix')}
              text={t('modalChooseIntegrationService.connectBitrixAndWeWillUploadProductsToYourAppWhenProductsAreUpdatedInTheBitrixFileTheyWillAlsoBeUpdatedHere')}
              isActive={false}
              hasInfo={false}
              extend={{
                title: rules.cardTitle
              }}
              onClick={openBitrix}
            />
          }
        </div>
      </div>
    </Modal>
  );
});

import {IProviderIntegration, ProviderIntegrationCase} from '../slices/integrationProductsSlice';
import {
  ProviderIntegrationGetStateResponse,
  ProviderIntegrationUpsertRequest
} from '@teleport/schemas-protobuf/port/v1/port_grpc_admin_pb';
import {
  ProviderIntegration_Bitrix,
  ProviderIntegration_Ozon,
  ProviderIntegration_Wildberries
} from '@teleport/schemas-protobuf';

export const IntegrationProductsTranslator = {
  fromProviderIntegrationGetStateResponse(response: ProviderIntegrationGetStateResponse) {
    return {
      syncState: response.syncState,
      provider: response.provider,
      lastSyncedAt: response.lastSyncedAt?.seconds ? Number(response.lastSyncedAt.seconds) * 1000 : undefined,
    }
  },

  toProviderIntegrationUpsertRequest(data: IProviderIntegration,) {
    switch (data.provider.case) {
      case ProviderIntegrationCase.Wildberries:
        return new ProviderIntegrationUpsertRequest({
          integration: {
            provider: {
              case: data.provider.case,
              value: new ProviderIntegration_Wildberries({
                token: data.provider.value.token
              })
            }
          }
        })
      case ProviderIntegrationCase.Ozon:
        return new ProviderIntegrationUpsertRequest({
          integration: {
            provider: {
              case: data.provider.case,
              value: new ProviderIntegration_Ozon({
                clientId: data.provider.value.clientId,
                apiKey: data.provider.value.apiKey,
              })
            }
          }
        })
      case ProviderIntegrationCase.Bitrix:
        return new ProviderIntegrationUpsertRequest({
          integration: {
            provider: {
              case: data.provider.case,
              value: new ProviderIntegration_Bitrix({
                fileUrl: data.provider.value.fileUrl,
              })
            }
          }
        })
    }
  },
};

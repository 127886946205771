import { FC, memo } from 'react';
import { IClasses } from 'src/utils/theme/fela.interface';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import { listButtonRules } from './ListButton.style';

interface IProps {
  id?: string;
  extend?: IClasses;
  placeholder?: string;
  title: string;
  text?: string;
  disabled?: boolean;
  onClick: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  bg?: string;
  padding?: string;
  titleUppercase?: boolean;
  titleFontSize?: number;
  titleFontWeight?: number;
  titleMarginBottom?: number;
  redPlaceholder?: boolean;
  hasChevron?: boolean;
}

export const ListButton: FC<IProps> = memo(function ListButton(props) {
  const {
    extend,
    title,
    placeholder,
    id,
    disabled,
    text,
    bg,
    padding,
    titleUppercase,
    titleFontSize,
    titleMarginBottom,
    titleFontWeight,
    onClick,
    redPlaceholder,
    hasChevron = true,
  } = props;
  const { css } = useStyle(listButtonRules, { disabled, bg, padding, titleMarginBottom });

  return (
    <>
      <div className={`${[css.list, extend].join(' ')} list-button`} onClick={onClick} id={id}>
        <div>
          <Text
            extend={css.title}
            text={title}
            textTransform={titleUppercase ? 'uppercase' : ''}
            mod="title"
            fontSize={titleFontSize ? titleFontSize : 16}
            lineHeight={18}
            fontWeight={titleFontWeight ?? 700}
          />
          {(text || placeholder ) && (
            <Text text={text ? text : placeholder} mod="text" fontSize={12} lineHeight={14} extend={redPlaceholder? css.redColor : ''} />
          )}
        </div>
        {hasChevron && (
          <div className={css.rightBlock}>
            <Button
              icon="chevron-right"
              propsStyles={{
                width: 20,
                height: 20,
                padding: 0,
                background: 'transparent',
              }}
              extend={css.isActive}
            />
          </div>
        )}
      </div>
    </>
  );
});

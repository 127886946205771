import {ChangeEvent, FC, memo, useCallback, useMemo} from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { Basket, setDeliveryType, wizardStateSelector } from '../../../redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { WizardOrderMethodProduct2Rules } from './WizardOrderMethodProduct2.style';
import {WIZARD_STEPS} from '../../../types/wizard';

export const WizardOrderMethodProduct2: FC = memo(function WizardOrderMethodProduct2() {
  const { wizard: { deliveryType, hasBasket }, orderOfSteps } = useAppSelector(wizardStateSelector);
  const { css } = useStyle(WizardOrderMethodProduct2Rules);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onChangeMethod = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    dispatch(setDeliveryType({deliveryType: value}));
  }, [dispatch]);

  const isSelected = value => {
    return deliveryType === value;
  };

  const stepNumber = useMemo(() => {
    return  orderOfSteps.indexOf(WIZARD_STEPS.ORDER_METHOD) + 1;
  }, [orderOfSteps])

  const disabledShowcaseOption = hasBasket === Basket.HasBasket;

  return (
    <Step
      stepNumber={stepNumber}
      title={t('wizardStepOrderMethod2.doYouNeedAShowcaseMode')}
      subtitle={t('wizardStepOrderMethod2.ifYouWantToDisplayProductsAndRedirectCustomerToYourWebsiteToPurchase')}
    >
      <div className={css.wrapper}>
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.yesAShowcaseIsNeeded')}
          text={t('wizardStepOrderMethod.theUserWillBeAbleToBrowseProductsInTheAppButWontBeAbleToPlaceOrders')}
          type="radio"
          value={`${DeliveryType.SHOWCASE}`}
          disabled={disabledShowcaseOption}
          disabledMessage={disabledShowcaseOption ? t('wizardStepOrderMethod.theShowcaseIsNotCompatibleWithTheCart') : ''}
          checked={disabledShowcaseOption ? false : isSelected(DeliveryType.SHOWCASE)}
          onChange={onChangeMethod}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.noWithoutAShowcase')}
          value={`${DeliveryType.REQUEST}`}
          text={t('wizardStepOrderMethod.theUserWillBeAbleToPlaceOrdersInTheApp')}
          type="radio"
          checked={isSelected(DeliveryType.REQUEST)}
          onChange={onChangeMethod}
        />
      </div>
    </Step>
  );
});

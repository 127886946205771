import {FC, memo, useEffect} from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { UploadedProductsPageRules } from './UploadedProductsPage.style';
import { ProductsCard } from 'src/components/ProductsCard/ProductsCard';
import { useModalState } from 'src/hooks/useModalState';
import { ModalSaveUploadedProducts } from 'src/components/ModalSaveUploadedProducts/ModalSaveUploadedProducts';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useAppSelector } from 'src/hooks/redux';
import { uploadedProductsState } from 'src/redux/slices/uploadedProductsSlice';
import {useLocation, useNavigate} from 'react-router-dom';
import {RoutePath} from '../../routing/routeConfig';
import useBackButton from '../../hooks/useBackButton';

export const UploadedProductsPage: FC = memo(function UploadedProductsPage() {
  
  const { css } = useStyle(UploadedProductsPageRules);
  const [renderSaveUploadedProductsModal, activeSaveUploadedProductsModal, openSaveUploadedProductsModal, closeSaveUploadedProductsModal] = useModalState();
  const { t } = useTranslation()
  const { uploadedProducts } = useAppSelector(uploadedProductsState)
  const navigate = useNavigate();
  const location = useLocation();
  const fileUnitArray: Uint8Array = location.state?.fileUnitArray;
  const BackButton = useBackButton();

  useEffect(() => {
    function goBack() {
      navigate(-1);
    }
    BackButton.onClickCustom(goBack);

    return () => {
      BackButton.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (!fileUnitArray) {
      navigate(RoutePath.Products)
    }
    if (fileUnitArray && uploadedProducts.length === 0) {
      navigate(RoutePath.Products, {})
    }
  }, [fileUnitArray, navigate, uploadedProducts]);

  return (
    <Layout
      padding={'4px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('uploadedProductsPage.uploadableProducts')}
            mod='title'
            fontWeight={800}
            fontSize={22}
            lineHeight={20}
          />
          <Text
            text={`${uploadedProducts.length}`}
            mod='text'
            fontWeight={400}
            fontSize={20}
            lineHeight={24}
            extend={css.span}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('uploadedProductsPage.uploadProducts')}
            propsStyles={{ width: '100%' }}
            onClick={openSaveUploadedProductsModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        <div className={css.productsWrapper}>
          {uploadedProducts.map(product => {
            return (
              <ProductsCard
                product={product}
                key={product.id}
                isActive={false}
                hidingFunctionality={false}
              />
            )
          })}
        </div>
      </Container>
      { renderSaveUploadedProductsModal && (
        <ModalSaveUploadedProducts
          active={activeSaveUploadedProductsModal}
          onClose={closeSaveUploadedProductsModal}
          fileUnitArray={fileUnitArray}
        />
      )}
    </Layout>
  );
});

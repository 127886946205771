import { FC, memo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { DefaultCardRules } from './DefaultCard.style';

interface IProps {
  title: string
  text: string
  uuid: string
  onClick: (uuid) => void
}

export const DefaultCard: FC<IProps> = memo(function DefaultCard(props) {
  const { title, text, uuid, onClick } = props;
  const { css } = useStyle(DefaultCardRules);
  const onClickFunction = () => {
    onClick(uuid)
  }

  return (
    <div className={css.card} id={`${uuid}`} onClick={onClickFunction}>
      <Text mod="title" text={title} fontSize={14} lineHeight={18} />      
      <p className={css.text}>{text}</p>
    </div>
  );
});

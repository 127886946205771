import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { Layout } from 'src/components/Layout/Layout';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { DeliveryPageRules } from './DeliveryPage.style';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { INoSettingsKeys } from 'src/components/NoSettings/NoSettings.style';
import { DefaultCard } from 'src/components/DefaultCard/DefaultCard';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { deliveryMethodList, deliveryMethodSelector, resetNetworkStatus } from 'src/redux/slices/deliveryMethodsSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import useLoader from 'src/hooks/useLoader';
import { openErrorModal } from 'src/redux/slices/modalErrorSlice';
import { togglesState } from 'src/redux/slices/configSlice';
import {useModalState} from '../../hooks/useModalState';
import { ModalAddDeliveryMethod } from 'src/components/ModalAddDeliveryMethod/ModalAddDeliveryMethod';

interface IProps {
  extend?: Partial<INoSettingsKeys>
}

export const DeliveryPage: FC<IProps> = memo(function DeliveryPage(props) {
  const { extend } = props;
  const { css } = useStyle(DeliveryPageRules, { extend });
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { deliveryMethods, getListNetworkStatus, errorText } = useAppSelector(deliveryMethodSelector)
  const dispatch = useAppDispatch();
  const [selectedMethodUuid, setSelectedMethodUuid] = useState('');
  const [renderDeliveryModal, activeDeliveryModal, openDeliveryModal, closeDeliveryModal] = useModalState();
  const { t } = useTranslation()
  const { showBackendErrors } = useAppSelector(togglesState);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (getListNetworkStatus === NetworkStatus.None) {
      dispatch(deliveryMethodList())
    }
    if (getListNetworkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (getListNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
    if (getListNetworkStatus === NetworkStatus.Failed) {
      hideLoader();
      dispatch(openErrorModal({
        errorTitle: t('deliveryPage.couldntReceiveDeliveryMethods'),
        errorText: showBackendErrors ? errorText : ''
      }));
    }
  }, [getListNetworkStatus, dispatch, hideLoader, showLoader, t, errorText, showBackendErrors]);

  const onClickDeliveryCard = uuid => {
    setSelectedMethodUuid(uuid);
    openDeliveryModal();
  }

  const onClickAddDeliveryMethod = () => {
    setSelectedMethodUuid('');
    openDeliveryModal();
  }


  useEffect(() => {
    return () => {
      dispatch(resetNetworkStatus('getListNetworkStatus'))
    }
  }, [dispatch])

  return (
    <Layout
      mainFlexColumn={true}
      padding={'6px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('deliveryPage.delivery')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('deliveryPage.addDeliveryMethod')}
            propsStyles={{ width: '100%' }}
            onClick={onClickAddDeliveryMethod}
          />
        </Footer>
      }
    >
      {deliveryMethods.length > 0 ? (
        <Container extend={css.container}>
          <div className={css.textBlocksWrapper}>
            {deliveryMethods.map(el => (
              <DefaultCard 
                key={el.value.uuid}
                uuid={el.value.uuid}
                title={el.value.name}
                text={el.value.description}
                onClick={onClickDeliveryCard}
              />
            ))}
          </div>
        </Container>
      ) : (
        <NoSettings
          title={t('deliveryPage.youHavenAddedAnyDeliveryMethodsYet')}
          text={t('deliveryPage.addDeliveryMethodsSoUsersCanChooseAndUnderstandHowAndWhereTheirOrderWillBeDelivered')}
          icon="delivery-large"
        />
      )}
      {renderDeliveryModal && <ModalAddDeliveryMethod active={activeDeliveryModal} onClose={closeDeliveryModal} deliveryMethodUuid={selectedMethodUuid} />}
    </Layout>
  );
});

import {IRuleFn} from '../../utils/theme/fela.interface';
import {createCachedStyle} from '../../utils/theme/createCachedStyle';
import {padding} from '../../utils/applyCSSProperty ';

export interface IIntegrationStatusRuleKeys {
  root: {};
  button: {};
  title: {};
  info: {};
}

interface IIntegrationStatusProps {
  extend?: Partial<IIntegrationStatusRuleKeys>
}

export type IIntegrationStatusRules = IRuleFn<IIntegrationStatusRuleKeys, IIntegrationStatusProps>;

const IntegrationStatusRuleFn: IIntegrationStatusRules = () => {

  return {
    root: {
      ...padding(20, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      marginBottom: 16,
    },
    title: {
      textAlign: 'center',
      marginBottom: 4,
    },
    info: {
      textAlign: 'center',
    }
  };
};

export const IntegrationStatusRules = createCachedStyle(IntegrationStatusRuleFn);

import {IRuleFn} from '../../utils/theme/fela.interface';
import {createCachedStyle} from '../../utils/theme/createCachedStyle';

export interface IMainPageKeys {
  wbOzonBanner: {}
}

export type IMainPage = IRuleFn<IMainPageKeys>;

const MainPageRuleFn: IMainPage = () => {
  return {
    wbOzonBanner: {
      marginBottom: 12,
      marginTop: 12,
    }
  };
};

export const MainPageRules = createCachedStyle(MainPageRuleFn);

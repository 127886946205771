import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface ITelegramIntegrationPageKeys {
  header: {};
}

export type ITelegramIntegrationPage = IRuleFn<ITelegramIntegrationPageKeys>;

const TelegramIntegrationPageRuleFn: ITelegramIntegrationPage = () => {
  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
  };
};

export const telegramIntegrationPageRules = createCachedStyle(TelegramIntegrationPageRuleFn);

import {memo, useCallback, useEffect, useRef, useState} from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { modalPromocodeRules } from './modalPromocode.style';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { resetNetworkStatus, setPromocodeFromUrl, wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import {NetworkStatus} from '../../utils/network/network.constant';
import { findEnumValueByPartialMatch } from 'src/utils/findEnumValueByPartialMatch';
import { LoyaltyError } from '@teleport/schemas-protobuf';

interface IProps {
  active: boolean;
  onClose: () => void;
  applyPromocode: (promocode: string) => void;
  value?: string;
}

const ModalPromocode = function ModalPromocode(props: IProps) {
  const dispatch = useAppDispatch();
  const { wizard, saveNetworkStatus, saveStateError } = useAppSelector(wizardStateSelector);
  const { active, value = '', applyPromocode, onClose } = props;
  const [promocode, setPromocode] = useState(value);
  const [correctCode, setCorrectCode] = useState(true);
  const [frontValidationError, setfrontValidationError] = useState<string | undefined>()
  const { css } = useStyle(modalPromocodeRules, { correctCode: correctCode && !frontValidationError });
  const { theme } = useTheme();
  const { t } = useTranslation();

  const modalInnerRef = useRef<HTMLDivElement>(null);

  useScrollModal(modalInnerRef);

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Failed && findEnumValueByPartialMatch(saveStateError, LoyaltyError) === LoyaltyError.PROMOCODE_EXPIRED) {
      setCorrectCode(false);
    }
  }, [saveNetworkStatus, saveStateError]);

  const onChangePromocode = event => {
    setCorrectCode(true);
    setPromocode(event.target.value);
    setfrontValidationError(undefined)
  };

  const clickKeyboard = useCallback(event => {
    const input = event.currentTarget;
    if (event.key === 'Enter') input.blur();
  }, []);

  const inputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.addEventListener('keypress', clickKeyboard);
      }
    },
    [clickKeyboard],
  );

  const checkPromocode = () => {
    if (promocode.length > 20) {
      setfrontValidationError(t('wizardModalPromo.cannotBeMoreThanSymbols', 20))
      return
    }
    if (promocode.length < 5) {
      setfrontValidationError(t('wizardModalPromo.cannotBeLessThanSymbols', 5))
      return
    }
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      { ...wizard, promocode: promocode.toUpperCase() },
      false,
    );
    dispatch(wizardSaveState({ requestData: wizardStateRequest, noNextStep: true }))
      .unwrap()
      .then(() => {
        setCorrectCode(true);
        applyPromocode(promocode);
        dispatch(setPromocodeFromUrl(undefined))
        onClose();
      })
      .catch(err => {
        console.error(err);
        setCorrectCode(false);
      });
  };

  const clearPromocode = () => {
    setPromocode('');
    applyPromocode('');
    setCorrectCode(true);
  };

  useEffect(() => {
    return () => {
      if (saveNetworkStatus === NetworkStatus.Failed) {
        dispatch(resetNetworkStatus('saveNetworkStatus'));
      }
    }
  }, [dispatch, saveNetworkStatus])

  return (
    <Modal
      active={active}
      name={ModalNames.PromoCode}
      propsStyles={{ paddingBottom: 30 }}
      onClose={onClose}
    >
      <div ref={modalInnerRef}>
        <div className={css.content}>
          <input
            ref={inputRef}
            placeholder={t('wizardModalPromo.placeholder')}
            name="promocode"
            type="text"
            className={css.inputPromocode}
            onChange={onChangePromocode}
            value={promocode}
          />
          {(!correctCode || frontValidationError) && (
            <Text
              text={frontValidationError? frontValidationError : t('wizardModalPromo.codeExpired')}
              mod="text"
              fontSize={12}
              lineHeight={16}
              extend={css.incorrect}
            />
          )}
        </div>
        <footer className={css.footer}>
          <Button
            text={t('wizardModalPromo.apply')}
            propsStyles={{
              width: '100%',
            }}
            disabled={!correctCode || !Boolean(promocode) || saveNetworkStatus === NetworkStatus.Loading}
            onClick={checkPromocode}
          />
          {value && (
            <Button
              icon="cross"
              propsStyles={{
                background: theme.colorGrey,
                padding: '0',
                width: 62,
                height: 62,
              }}
              onClick={clearPromocode}
              extend={css.btnClear}
            />
          )}
        </footer>
      </div>
    </Modal>
  );
};

export default memo(ModalPromocode);

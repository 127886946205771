import { useEffect } from 'react';
import { hasTouchSupport } from 'src/utils/hasTouchSupport';

// !!! modalInner must have style:
// {position: relative}

export const useScrollModal = (
  modalInnerRef: React.MutableRefObject<HTMLDivElement | HTMLFormElement>,
  ...args: any
) => {
  const modalInner = modalInnerRef.current;

  useEffect(() => {
    if (!modalInner) return undefined;
    const windowHeight = document.documentElement.clientHeight;
    const expanderDiv = document.createElement('div');
    expanderDiv.id = 'expander';
    expanderDiv.style.width = '100%';
    expanderDiv.style.background = 'transparent';
    expanderDiv.style.flexShrink = '0';
    const expanderStep = 50;

    function onFocus(event) {
      if (!hasTouchSupport()) return;
      const input = event.currentTarget;
      const inputWrapper: HTMLDivElement =
        event.currentTarget.closest('.inputWrapper') ||
        event.currentTarget.closest('.textareaWrapper');

      let inputCoordinates = null;

      if (inputWrapper) {
        modalInner.appendChild(expanderDiv);
        modalInner.style.overflow = 'hidden';
        inputCoordinates = inputWrapper.getBoundingClientRect();
        for (let i = 1; inputCoordinates.top > windowHeight / 3; i++) {
          expanderDiv.style.height = `${i * expanderStep}px`;
          modalInner.scrollTop = inputWrapper.offsetTop - 40;
          inputCoordinates = inputWrapper.getBoundingClientRect();
          if (i * expanderStep > document.documentElement.clientHeight) break;
        }
      } else {
        modalInner.appendChild(expanderDiv);
        modalInner.style.overflow = 'hidden';
        inputCoordinates = input.getBoundingClientRect();
        for (let i = 1; inputCoordinates.top > windowHeight / 3; i++) {
          expanderDiv.style.height = `${i * expanderStep}px`;
          modalInner.scrollTop = input.offsetTop - 40;
          inputCoordinates = input.getBoundingClientRect();
          if (i * expanderStep > document.documentElement.clientHeight) break;
        }
      }
    }

    function onBlur() {
      // Timeout фиксит проблему сдвига кнопки, выше клавиатуры
      // При Блюре у нас пропадает expanderElement кнопка возвращается вниз на своё прежнее место и пользователь кликает в пустосту, модалка просто закрывается, клик по кнопке не происходит. Поэтому мы откладываем событие
      setTimeout(() => {
        modalInner.style.overflow = '';
        expanderDiv.style.height = '';
        const expanderElement = document.getElementById('expander');
        if (expanderElement) {
          expanderElement.remove()
        }
      }, 100)
    }

    const allInputs = modalInner.querySelectorAll('input, textarea');
    const inputs = [...allInputs].filter(el => {
      if (el instanceof HTMLInputElement) {
        return el.type !== 'radio' && el.type !== 'checkbox' && el.type !== 'file';
      }
      return true;
    });

    inputs.forEach(input => {
      input.addEventListener('focus', onFocus);
      input.addEventListener('blur', onBlur);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', onFocus);
        input.removeEventListener('blur', onBlur);
      });
    };
    // eslint-disable-next-line
  }, [modalInner, ...args]);
};

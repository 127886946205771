export enum UrlSearchParams {
  loyaltyPromocode = 'loyaltyPromocode',
  wizardType = 'wizardType',
}

export const getSearchParams = (param: UrlSearchParams) => {
  if (typeof window === 'undefined' || !window.location) return null;
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

import {FC, memo, useEffect, useMemo, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import {useScrollModal} from '../../hooks/useScrollModal';
import {SubmitHandler, useForm} from 'react-hook-form';
import {NetworkStatus} from '../../utils/network/network.constant';
import {useStyle} from '../../utils/theme/useStyle';
import {useTheme} from '../../utils/theme/useTheme';
import Modal, {ModalNames} from '../Modal/Modal';
import Text from '../UI/Text/Text';
import {Input} from '../UI/Input/Input';
import {Button} from '../UI/Button/Button';
import {ModalAddDeliveryMethodRules} from './ModalAddDeliveryMethod.style';
import {
  createDeliveryMethod,
  DeliveryCases, deliveryMethodDelete,
  deliveryMethodSelector, resetNetworkStatus, upsertDeliveryMethod,
} from '../../redux/slices/deliveryMethodsSlice';
import {openErrorModal} from '../../redux/slices/modalErrorSlice';
import {togglesState} from '../../redux/slices/configSlice';

interface IProps {
  active: boolean;
  onClose: () => void;
  deliveryMethodUuid?: string;
}

interface IFormStory {
  name: string;
  comment: string;
}

export const ModalAddDeliveryMethod: FC<IProps> = memo(function ModalAddDeliveryMethod(props) {
  const { active, onClose, deliveryMethodUuid } = props;
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    upsertNetworkStatus,
    createNetworkStatus,
    deleteNetworkStatus,
    errorText,
  } = useAppSelector(deliveryMethodSelector);
  const { showBackendErrors } = useAppSelector(togglesState)
  const { theme } = useTheme();
  const { css } = useStyle(ModalAddDeliveryMethodRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });
  const { deliveryMethods } = useAppSelector(deliveryMethodSelector);
  const deliveryMethodData = useMemo(() => {
    return deliveryMethods.find(el => el.value.uuid === deliveryMethodUuid);
  }, [deliveryMethods, deliveryMethodUuid])

  // scroll modal on focus
  useScrollModal(modalInnerRef);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: deliveryMethodData?.value.name ?? '',
      comment: deliveryMethodData?.value.description ?? '',
    },
  });

  const disabledButton = useMemo(() => {
    return (
      upsertNetworkStatus === NetworkStatus.Loading
      || createNetworkStatus === NetworkStatus.Loading
      || deleteNetworkStatus === NetworkStatus.Loading
    )
  }, [
    upsertNetworkStatus,
    createNetworkStatus,
    deleteNetworkStatus
  ])

  useEffect(() => {
    // Done
    if (upsertNetworkStatus === NetworkStatus.Done) {
      onClose();
      dispatch(resetNetworkStatus('upsertNetworkStatus'));
      return
    }
    if (createNetworkStatus === NetworkStatus.Done) {
      onClose();
      dispatch(resetNetworkStatus('createNetworkStatus'));
      return
    }
    if (deleteNetworkStatus === NetworkStatus.Done) {
      onClose();
      dispatch(resetNetworkStatus('deleteNetworkStatus'));
      return
    }

    // Failed
    if (upsertNetworkStatus === NetworkStatus.Failed) {
      onClose();
      dispatch(resetNetworkStatus('upsertNetworkStatus'));
      dispatch(openErrorModal({
        errorTitle: t('modalAddDeliveryMethod.failedToUpdateDeliveryMethod'),
        errorText: showBackendErrors ? errorText : ''
      }));
      return
    }
    if (createNetworkStatus === NetworkStatus.Failed) {
      onClose();
      dispatch(resetNetworkStatus('createNetworkStatus'));
      dispatch(openErrorModal({
        errorTitle: t('modalAddDeliveryMethod.failedToCreateDeliveryMethod'),
        errorText: showBackendErrors ? errorText : ''
      }));
      return
    }
    if (deleteNetworkStatus === NetworkStatus.Failed) {
      onClose();
      dispatch(resetNetworkStatus('deleteNetworkStatus'));
      dispatch(openErrorModal({
        errorTitle: t('modalAddDeliveryMethod.failedToDeleteDeliveryMethod'),
        errorText: showBackendErrors ? errorText : ''
      }));
      return
    }
  }, [
    t,
    errorText,
    showBackendErrors,
    dispatch,
    onClose,
    upsertNetworkStatus,
    createNetworkStatus,
    deleteNetworkStatus
  ]);

  const onSubmit: SubmitHandler<IFormStory> = data => {
    const { name, comment } = data;
    if (deliveryMethodData) {
      dispatch(upsertDeliveryMethod({
        case: DeliveryCases.customMethod,
        value: {
          name,
          description: comment,
          uuid: deliveryMethodData.value.uuid
        }
      }))
    } else {
      dispatch(createDeliveryMethod({
        case: DeliveryCases.customMethod,
        value: {
          name,
          description: comment,
          uuid: '',
        }
      }))
    }
  };

  const onClickDelete = () => {
    dispatch(deliveryMethodDelete(deliveryMethodData.value.uuid));
  }

  return (
    <Modal
      name={ModalNames.SettingStory}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalAddDeliveryMethod.deliveryMethod')} mod='title' fontSize={22} fontWeight={800} lineHeight={26} />
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className={css.block}>
            <Input
              {...register('name', {required: t('modalAddDeliveryMethod.requiredField'), maxLength: 60})}
              type='text'
              label={t('modalAddDeliveryMethod.name')}
              controlled={false}
              letterCounter={true}
              placeholder={t('modalAddDeliveryMethod.chooseAMethodName')}
              propsStyles={{backgroundColor: theme.background}}
              maxLength={60}
              errorMessage={errors.name?.message}
              labelTextTransform='uppercase'
            />
          </div>
          <div className={css.commentBlock}>
            <Input
              {...register('comment', {maxLength: 60})}
              type='text'
              label={t('modalAddDeliveryMethod.comment')}
              controlled={false}
              letterCounter={true}
              placeholder={t('modalAddDeliveryMethod.describeTheDetails')}
              propsStyles={{backgroundColor: theme.background}}
              maxLength={60}
              errorMessage={errors.comment?.message}
              labelTextTransform='uppercase'
            />
          </div>
          {deliveryMethodData && (
            <div className={css.block}>
              <Button
                text={t('modalSettingStory.delete')}
                propsStyles={{ background: theme.colorGrey, color: theme.colorBlack, width: '100%' }}
                hasGradient={false}
                disabled={upsertNetworkStatus === NetworkStatus.Loading || deleteNetworkStatus === NetworkStatus.Loading}
                onClick={onClickDelete}
              />
            </div>
          )}
          <div className={css.footer} ref={modalFooterRef}>
            <Button
              disabled={disabledButton}
              text={t('modalAddDeliveryMethod.saveDeliveryMethod')}
              propsStyles={{width: '100%'}}
              type='submit'
            />
          </div>
        </form>
      </div>
    </Modal>
  );
});

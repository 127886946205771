import { Interceptor, ConnectError } from '@connectrpc/connect';
import { AuthenticationError } from '@teleport/schemas-protobuf';
import { getConfig } from 'src/utils/config/getConfig';
import { findEnumValueByPartialMatch } from 'src/utils/findEnumValueByPartialMatch';

export const statusCodeInterceptor: Interceptor = next => async request => {
  let res;
  try {
    res = await next(request);
  } catch (err) {
    const errorMessage = err.message
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }

    if (err instanceof ConnectError) {
      if (err.code === 16) {
        const config = await getConfig();
        if (findEnumValueByPartialMatch(errorMessage, AuthenticationError) === AuthenticationError.UNAUTHENTICATED && config.toggles?.authCustomErrorEnabled) {
          if (process.env.NODE_ENV === 'development') {
            console.error('UNAUTHENTICATED', errorMessage);
          }
          throw err;
        }
        const hubBotUsername = window.localStorage.getItem('hubBotUsername');
        // window.Telegram.WebApp.openLink(`https://t.me/${hubBotUsername}/port`);
        location.href = `https://t.me/${hubBotUsername}/hub`;
      }
    }
    throw err;
  }
  return res;
};

import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from '../UI/Text/Text';
import {CardInfoBtnRules, ICardInfoBtnRuleKeys} from './CardInfoBtn.style';
import { Icon } from '../UI/Icon/Icon';

interface IProps {
  title: string;
  text: string;
  isActive: boolean;
  onClick?: () => void;
  onInfoClick?: () => void;
  hasInfo?: boolean;
  extend?: Partial<ICardInfoBtnRuleKeys>
}

export const CardInfoBtn: FC<IProps> = memo(function StepsIndicator(props) {
  const { title, text, isActive, onClick, onInfoClick, hasInfo = true, extend } = props;

  const { css } = useStyle(CardInfoBtnRules, { isActive, extend });

  return (
    <div className={css.cardWrapper} onClick={() => onClick && onClick()}>
      <div>
        <Text text={title} fontWeight={700} fontSize={16} lineHeight={18} mod="title" extend={css.title} />
        <Text text={text} mod="text" fontSize={12} lineHeight={14} />
      </div>
      <div
        className={css.icon}
        onClick={e => {
          e.stopPropagation();
          onInfoClick();
        }}
      >
        {hasInfo && <Icon name={'info'} />}
      </div>
    </div>
  );
});

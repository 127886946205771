import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { IProduct } from 'src/types/product';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { ImportType } from '@teleport/schemas-protobuf';
import { UploadedProductsTranslator } from '../translators/uploadedProductsTranslator';

interface IUploadedProductsSlice{
  networkStatus: NetworkStatus;
  errorText: string;
  uploadedProducts: IProduct[]
}

export interface ICatalogImportRequest {
  importType: ImportType;
  fileBlob: Uint8Array;
}

export const uploadProducts = createAppAsyncThunk(
  'uploadProducts',
  async (data: ICatalogImportRequest, thunkAPI) => {
    const result = await thunkAPI.extra.portAdminApi().catalogImport(data);
    return UploadedProductsTranslator.fromCatalogImportResponse(result)
  },
);

const initialState: IUploadedProductsSlice= {
  networkStatus: NetworkStatus.None,
  errorText: '',
  uploadedProducts: [],
};

export const uploadedProductsSlice = createSlice({
  name: 'uploadedProducts',
  initialState,
  reducers: {
    resetUploadedProductsNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(uploadProducts.pending, state => {
      state.errorText = ''
      state.networkStatus = NetworkStatus.Loading;
    })
    builder.addCase(uploadProducts.rejected, (state, action) => {
      if (action.error?.message) {
        state.errorText = action.error?.message
      }
      state.networkStatus = NetworkStatus.Failed;
    })
    builder.addCase(uploadProducts.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done;
      state.uploadedProducts = action.payload;
    })
  }
});

export const { resetUploadedProductsNetworkStatus } = uploadedProductsSlice.actions;

export const uploadedProductsState = (state: RootState) => state[uploadedProductsSlice.name];

import { addPx, padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IDefaultCardKeys {
  card: {};
  text: {};
}

export type IDefaultCard = IRuleFn<IDefaultCardKeys>;

const DefaultCardRuleFn: IDefaultCard = props => {
  const { colorWhite } = props.theme;

  return {
    card: {
      borderRadius: 12,
      backgroundColor: colorWhite,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      ...padding(12),
    },
    text: {
      fontSize: 12,
      lineHeight: addPx(16),
      fontWeight: 500,
    }
  };
};

export const DefaultCardRules = createCachedStyle(DefaultCardRuleFn);

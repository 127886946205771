import { FC, memo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { ImageComponent } from 'src/components/ImageComponent/ImageComponent';
import { NewsletterCardRules } from './NewsletterCard.style';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { formatDate } from '../../utils/date/formatDate';
import { formatTime } from '../../utils/date/formatTIme';
import { INewsLetter } from '../../redux/slices/newslettersSlice';

interface IProps {
newsletterCardData: INewsLetter,
}

export const NewsletterCard: FC<IProps> = memo(function NewsletterCard(props) {
  const { newsletterCardData } = props;
  const { imageUrl, title, messageText, statistic, lifecycle } = newsletterCardData;
  const [isOpen, setIsOpen] = useState(false);
  const { t, tPlural } = useTranslation();
  const { css } = useStyle(NewsletterCardRules, {isOpen});
  const toggleTextOpenState = () => {
    setIsOpen(!isOpen);
  }

  const timestamp = Number(lifecycle.createdAt.seconds);
  const time = formatTime(timestamp);
  const humanReadableDate = formatDate(timestamp);

  return (
    <div className={css.card}>
      <ImageComponent
        className={css.image}
        alt="slide-img"
        src={imageUrl}
        fallback={<Icon name="no-image" properties={{ className: css.image}} />}
      />
      <div className={css.info}>
        <Text
          mod="text"
          text={title}
          fontSize={14}
          lineHeight={16}
          fontWeight={800}
        />
        <div className={css.text}>
          <div className={css.textInner}>
            {messageText}
          </div>
        </div>
        <button className={css.showMore} onClick={toggleTextOpenState}>
          <p>{isOpen ? t('newsletterCard.hide') : t('newsletterCard.viewFull')}</p>
          <Icon name="chevron-right" properties={{ className: css.iconChevron }}   />
        </button>
        <p className={css.time}>{time}</p>
        <div className={css.countRow}>
          <p>{tPlural('newsletterCard.people', statistic.total)}</p>
          <p>{humanReadableDate}</p>
        </div>
      </div>
    </div>
  );
});

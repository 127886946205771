import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { botIsNotAdminError } from 'src/utils/customErrors';

export enum CustomErrorType {
  AuthenticationError = 'AuthenticationError'
}
interface IModalErrorSlice {
  modalErrorIsOpen: boolean;
  errorTitle:  string;
  errorText: string;
  customErrorFromErrorMiddleware?: CustomErrorType;
}

const initialState: IModalErrorSlice = {
  modalErrorIsOpen: false,
  errorTitle: '',
  errorText: '',
  customErrorFromErrorMiddleware: undefined
};

export const modalErrorSlice = createSlice({
  name: 'ModalError',
  initialState,
  reducers: {
    openErrorModal: (state, action: PayloadAction<{
      errorTitle?: string,
      errorText?: string,
    }>) => {
      if (state.customErrorFromErrorMiddleware) {
        return
      }
      if(action.payload.errorText?.includes(botIsNotAdminError)) return
      state.modalErrorIsOpen = true;
      if (action.payload) {
        state.errorTitle = action.payload.errorTitle;
        state.errorText = action.payload.errorText
      }
    },
    closeErrorModal: state => {
      state.modalErrorIsOpen = false;
      state.errorTitle = '';
      state.errorText = '';
      state.customErrorFromErrorMiddleware = undefined;
    },
    setCustomErrorFromErrorMiddleware: (state, action: PayloadAction<CustomErrorType | undefined>) => {
      state.customErrorFromErrorMiddleware = action.payload
    }
  },
});

export const { openErrorModal, closeErrorModal, setCustomErrorFromErrorMiddleware } = modalErrorSlice.actions;

export const modalErrorState = (state: RootState) => state[modalErrorSlice.name];

import { FC, memo, useEffect, useMemo } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Container from 'src/components/Container/Container';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { newslettersPageRules } from './NewslettersPage.style';
import useLoader from 'src/hooks/useLoader';
import { NewsletterCard } from 'src/components/NewsletterCard/NewsletterCard';
import useBackButton from '../../hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getNewsLetterList, newslettersSelector } from '../../redux/slices/newslettersSlice';
import { NetworkStatus } from '../../utils/network/network.constant';

export const NewslettersPage: FC = memo(function NewslettersPage() {
  const { css } = useStyle(newslettersPageRules);
  const { hideLoader, showLoader } = useLoader();
  const BackButton = useBackButton();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { newsLetters, newslettersListStatus } = useAppSelector(newslettersSelector);

  useEffect(() => {
    if(newslettersListStatus === NetworkStatus.None) {
      dispatch(getNewsLetterList());
    }
    if(newslettersListStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if(newslettersListStatus === NetworkStatus.Done || newslettersListStatus === NetworkStatus.Failed) {
      hideLoader()
    }
  }, [dispatch, newslettersListStatus, showLoader, hideLoader]);

  useEffect(() => {
    function goBack() {
      navigate('/');
    }
    BackButton.onClickCustom(goBack);

    return () => {
      BackButton.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const renderNewslettersInnerContent = useMemo(() => {
    if (newsLetters?.length > 0) {
      return (
        <Container>
          <div className={css.grid}>
            {newsLetters.map(newsletter => {
              return (
                <NewsletterCard
                  key={newsletter.uuid}
                  newsletterCardData={newsletter}
                />
              );
            })}
          </div>
        </Container>
      )
    }
    return (
      <NoSettings
        text={t('newslettersPage.createANewsletterAndTheBotWillSendItToAllSubscribedUsers')}
        title={t('newslettersPage.youHaventCreatedAnyNewslettersYet')}
        icon={'newsletters-large'}
      />
    );
  }, [t, newsLetters, css.grid]);

  return (
    <Layout
      mainFlexColumn={true}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('newslettersPage.newsletters')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            href={RoutePath.CreateNewsletter}
            text={t('newslettersPage.createNewsletter')}
            propsStyles={{ width: '100%' }}
          />
        </Footer>
      }
    >
      {renderNewslettersInnerContent}
    </Layout>
  );
});


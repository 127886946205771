import { DeliveryMethodListResponse, DeliveryMethodUpsertRequest } from '@teleport/schemas-protobuf';
import { DeliveryCases, IDeliveryMethod } from '../slices/deliveryMethodsSlice';

export const DeliveryMethodTranslator = {
  fromDeliveryMethodListResponse(response: DeliveryMethodListResponse): IDeliveryMethod[]  {
    return response.deliveryMethods.map(el => {
      switch (el.deliveryMethod.case) {
        case DeliveryCases.customMethod:
          return {
            value: {
              uuid: el.deliveryMethod.value.uuid,
              name: el.deliveryMethod.value.name,
              description: el.deliveryMethod.value.description,
            },
            case: DeliveryCases.customMethod
          }
        default:
          return undefined
      }
    }).filter(Boolean)
  },
  toDeliveryMethodUpsertRequest(deliveryMethod: IDeliveryMethod): DeliveryMethodUpsertRequest {
    switch (deliveryMethod.case) {
      case DeliveryCases.customMethod:
        return new DeliveryMethodUpsertRequest({
          deliveryMethod: {
            deliveryMethod: {
              value: {
                uuid: deliveryMethod.value.uuid ?? '',
                name: deliveryMethod.value.name,
                description: deliveryMethod.value.description,
              },
              case: deliveryMethod.case
            }
          }
        })
    }
  }
};

import {FC, memo, useRef} from 'react';
import {useStyle} from 'src/utils/theme/useStyle';
import Modal, {ModalNames} from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import {modalWbRules} from './ModalWb.style';
import {Input} from '../UI/Input/Input';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'src/utils/i18n/hooks/useTranslation';
import {useTheme} from 'src/utils/theme/useTheme';
import {Button} from '../UI/Button/Button';
import {Link} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/redux';
import {
  ProviderIntegrationCase,
  providerIntegrationUpsert,
} from '../../redux/slices/integrationProductsSlice';
import { useScrollModal } from 'src/hooks/useScrollModal';

interface IProps {
  active: boolean;
  onClose: () => void;
  onSubmitCallback: (provider: ProviderIntegrationCase) => void
}

interface IFormWb {
  wbToken: string;
}

export const ModalWb: FC<IProps> = memo(function ModalWb(props) {
  const { onClose, onSubmitCallback } = props;
  const { theme } = useTheme();
  const { t } = useTranslation();
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLFormElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalWbRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({
    defaultValues: {
      wbToken: '',
    },
  });

  const onSubmit: SubmitHandler<IFormWb> = data => {
    onSubmitCallback(ProviderIntegrationCase.Wildberries);
    dispatch(providerIntegrationUpsert({
      provider: {
        value: {
          token: data.wbToken
        },
        case: ProviderIntegrationCase.Wildberries
      }
    }))
  };

  // scroll modal on focus
  useScrollModal(modalInnerRef);

  return (
    <Modal
      name={ModalNames.ModifierType}
      active={true}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
      // expand={true}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalWb.wBconnection')} mod='title' fontSize={22} lineHeight={46}/>
      </div>
      <form ref={modalInnerRef} onSubmit={handleSubmit(onSubmit)} className={css.modalInner} >
        <div className={css.formInner}>
          <Text text={t('modalWb.token')} mod='text' fontWeight={700} fontSize={14} lineHeight={18}/>
          <Text text={t('modalWb.createAtokenInTheWbPartnersAccountSettingsInTheApiAccessSection')} mod='text' extend={css.inputLabelText} fontWeight={400} fontSize={12} lineHeight={14}/>
          <Input
            controlled={false}
            {...register('wbToken', {
              required: t('modalWb.requiredField'),
            })}
            type='text'
            maxLength={200}
            errorMessage={errors.wbToken?.message}
            propsStyles={{backgroundColor: theme.background}}
            placeholder={t('modalWb.insertTheTokenFromWb')}
          />
          <Link className={css.link} to="#">
            <Text text={t('modalWb.viewTheInstructions')} mod='text' fontWeight={600} extend={css.link}  fontSize={12} lineHeight={16}/>
          </Link>
        </div>
        <div className={css.footer} ref={modalFooterRef}>
          <Button
            text={t('modalWb.connect')}
            propsStyles={{ width: '100%' }}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
});

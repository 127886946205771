import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IDeliveryPageKeys {
  wrapper: {};
  header: {};
  textBlocksWrapper: {};
  noSettings: {};
  btnWrp: {};
  container: {};
}

export type IDeliveryPage = IRuleFn<IDeliveryPageKeys>;

const DeliveryPageRuleFn: IDeliveryPage = () => {

  return {
    header: {
      ...padding(16),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    noSettings: {
      minHeight: 'unset',
      flexGrow: 1,
    },
    wrapper: {},
    textBlocksWrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: 8,
    },
    btnWrp: {
      paddingBottom: 8,
    },
    container: {
      flexGrow: 1,
    }
  };
};

export const DeliveryPageRules = createCachedStyle(DeliveryPageRuleFn);

import { MainPage } from 'src/pages/MainPage/MainPage';
import { RouteObject } from 'react-router-dom';
import { NotFoundPage } from 'src/pages/NotFoundPage/NotFoundPage';
import { WizardPage } from 'src/pages/WizardPage/WizardPage';
// import { SubcategoryPage } from 'src/pages/SubcategoryPage/SubcategoryPage';
import { CategoryPage } from 'src/pages/CategoryPage/CategoryPage';
import { SettingStoriesPage } from 'src/pages/SettingStoriesPage/SettingStoriesPage';
import { SettingTextBlocksPage } from 'src/pages/SettingTextBlocksPage/SettingTextBlocksPage';
import { SettingSliderPage } from 'src/pages/SettingSliderPage/SettingSliderPage';
import { SelectionsPage } from 'src/pages/SelectionsPage/SelectionsPage';
import { SettingCategoriesPage } from 'src/pages/SettingCategoriesPage/SettingCategoriesPage';
import { SettingCreateCategoryPage } from 'src/pages/SettingCreateCategoryPage/SettingCreateCategoryPage';
import { SettingEditCategoryPage } from 'src/pages/SettingEditCategoryPage/SettingEditCategoryPage';
import { ProductsPage } from 'src/pages/ProductsPage/ProductsPage';
import { CreateProductPage } from 'src/pages/CreateProductPage/CreateProductPage';
// import { SettingCreateSubCategoryPage } from 'src/pages/SettingCreateSubCategoryPage/SettingCreateSubCategoryPage';
// import { SettingEditSubCategoryPage } from 'src/pages/SettingEditSubCategoryPage/SettingEditSubCategory';
import { ModifiersPage } from 'src/pages/ModifiersPage/ModifiersPage';
import { CreateModifierPage } from 'src/pages/CreateModifierPage/CreateModifierPage';
import { SettingBlocksOrder } from 'src/pages/SettingBlocksOrder/SettingBlocksOrder';
import { EditProductPage } from 'src/pages/EditProductPage/EditProductPage';
import { EdiModifierPage } from 'src/pages/EditModifierPage/EditModifierPage';
import { ProfilePage } from 'src/pages/ProfilePage/ProfilePage';
import { SubscriptionPage } from 'src/pages/SubscriptionPage/SubscriptionPage';
import { StoreSetupPage } from 'src/pages/StoreSetupPage/StoreSetupPage';
import { SelectColorPage } from 'src/pages/SelectColorPage/SelectColorPage';
import { OrderingAndDeliveryPage } from 'src/pages/OrderingAndDeliveryPage/OrderingAndDeliveryPage';
import { RecommendationsPage } from 'src/pages/RecommendationsPage/RecommendationsPage';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { PromoCodesPage } from '../pages/PromoСodesPage/PromoСodesPage';
import { EditPromoCodePage } from '../pages/EditPromoCodePage/EditPromoCodePage';
import { CreatePromoCodePage } from '../pages/CreatePromoCodePage/CreatePromoCodePage';
import { NewslettersPage } from '../pages/NewslettersPage/NewslettersPage';
import { CreateNewsLetterPage } from '../pages/CreateNewsLetterPage/CreateNewsLetterPage';
import { ManageAdministratorsPage } from '../pages/ManageAdministratorsPage/ManageAdministratorsPage';
import { EditSelectionPage } from 'src/pages/EditSelectionPage/EditSelectionPage';
import { CreateSelectionPage } from 'src/pages/CreateSelectionPage/CreateSelectionPage';
import { SelectionProductPage } from 'src/pages/SelectionProductPage/SelectionProductPage';
import { IntegrationsPage } from 'src/pages/IntegrationsPage/IntegrationsPage';
import { TelegramIntegrationPage } from 'src/pages/TelegramIntegrationPage/TelegramIntegrationPage';
import {ProductsIntegrationPage} from '../pages/ProdcutsIntegration/ProdcutsIntegration';
import { UploadedProductsPage } from 'src/pages/UploadedProductsPage/UploadedProductsPage';
import { PaymentPage } from 'src/pages/PaymentPage/PaymentPage';
import { DeliveryPage } from 'src/pages/DeliveryPage/DeliveryPage';

export enum AppRoutes {
  Main = 'Main',
  Wizard = 'Wizard',
  Profile = 'Profile',

  Products = 'Products',
  UploadedProducts = 'UploadedProducts',
  CreateProduct = 'CreateProduct',
  EditProduct = 'EditProduct',

  Modifiers = 'Modifiers',
  CreateModifier = 'CreateModifier',
  EditModifier = 'EditModifier',

  Categories = 'Categories',
  Category = 'Category',
  CreateCategory = 'CreateCategory',
  EditCategory = 'EditCategory',
  // Subcategory = 'Subcategory',
  // EditSubcategory = 'EditSubcategory',
  // CreateSubcategory = 'CreateSubcategory',

  Stories = 'Stories',
  TextBlocks = 'TextBlocks',
  Slider = 'Slider',
  BlocksOrder = 'BlocksOrder',
  Selections = 'Selections',
  Payment = 'Payment',
  Delivery = 'Delivery',
  EditSelection = 'EditSelection',
  CreateSelection = 'CreateSelection',
  SelectionProducts = 'SelectionProducts',

  Subscription = 'Subscription',
  StoreSetup = 'StoreSetup',
  Integrations = 'Integrations',
  IntegrationTelegram = 'IntegrationTelegram',
  IntegrationProducts = 'IntegrationProducts',
  SelectColor = 'SelectColor',
  OrderingAndDelivery = 'OrderingAndDelivery',
  Recommendation = 'Recommendation',

  Newsletters = 'Newsletters',
  CreateNewsletter = 'CreateNewsletter',

  Administrators = 'Administrators',

  PromoCodes = 'PromoCodes',
  CreatePromoCode = 'CreatePromoCode',
  EditPromoCode = 'EditPromoCode'
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.Main]: '/',
  [AppRoutes.Wizard]: '/wizard',
  [AppRoutes.Profile]: '/profile',

  [AppRoutes.Products]: '/products',
  [AppRoutes.UploadedProducts]: '/uploaded-products',
  [AppRoutes.CreateProduct]: '/products/create',
  [AppRoutes.EditProduct]: '/products/:id/edit',

  [AppRoutes.Modifiers]: '/modifiers',
  [AppRoutes.CreateModifier]: '/modifiers/create',
  [AppRoutes.EditModifier]: '/modifiers/:id/edit',

  [AppRoutes.Categories]: '/categories',
  [AppRoutes.Category]: '/categories/:id',
  [AppRoutes.CreateCategory]: '/categories/create',
  [AppRoutes.EditCategory]: '/categories/:id/edit',
  // [AppRoutes.Subcategory]: '/categories/subcategory/:id',
  // [AppRoutes.CreateSubcategory]: '/categories/subcategory/create',
  // [AppRoutes.EditSubcategory]: '/categories/subcategory/edit/:id',

  [AppRoutes.Stories]: '/stories',
  [AppRoutes.TextBlocks]: '/text-blocks',
  [AppRoutes.Slider]: '/slider',
  [AppRoutes.BlocksOrder]: '/blocks-order',
  [AppRoutes.Selections]: '/selections',
  [AppRoutes.Payment]: '/payment',
  [AppRoutes.Delivery]: '/delivery',
  [AppRoutes.EditSelection]: '/selection/:id/edit',
  [AppRoutes.SelectionProducts]: '/selection/:id/products',
  [AppRoutes.CreateSelection]: '/selection/create',

  [AppRoutes.Subscription]: '/subscription',
  [AppRoutes.StoreSetup]: '/store-setup',
  [AppRoutes.Integrations]: '/integrations',
  [AppRoutes.IntegrationTelegram]: '/integrations/telegram',
  [AppRoutes.IntegrationProducts]: '/integrations/products',
  [AppRoutes.SelectColor]: '/select-color',
  [AppRoutes.OrderingAndDelivery]: '/ordering-and-delivery',
  [AppRoutes.Recommendation]: '/recommendation',

  [AppRoutes.Newsletters]: '/newsletters',
  [AppRoutes.CreateNewsletter]: '/newsletters/create',
  [AppRoutes.PromoCodes]: '/promo-codes',
  [AppRoutes.CreatePromoCode]: '/promo-codes/create',
  [AppRoutes.EditPromoCode]: '/promo-codes/:id/edit',

  [AppRoutes.Administrators]: '/administrators',
};

export const routeConfig: RouteObject[] = [
  {
    path: RoutePath.Main,
    element: <ProtectedRoutes/>,
    children: [
      {
        path: RoutePath.Main,
        element: <MainPage/>,
      },
      {
        path: RoutePath.Profile,
        element: <ProfilePage/>,
      },
      {
        path: RoutePath.Products,
        element: <ProductsPage/>,
      },
      {
        path: RoutePath.UploadedProducts,
        element: <UploadedProductsPage/>,
      },
      {
        path: RoutePath.CreateProduct,
        element: <CreateProductPage/>,
      },
      {
        path: RoutePath.EditProduct,
        element: <EditProductPage/>,
      },
      {
        path: RoutePath.Modifiers,
        element: <ModifiersPage/>,
      },
      {
        path: RoutePath.CreateModifier,
        element: <CreateModifierPage/>,
      },
      {
        path: RoutePath.EditModifier,
        element: <EdiModifierPage/>,
      },
      {
        path: RoutePath.Categories,
        element: <SettingCategoriesPage/>,
      },
      {
        path: RoutePath.Category,
        element: <CategoryPage/>,
      },
      {
        path: RoutePath.CreateCategory,
        element: <SettingCreateCategoryPage/>,
      },
      {
        path: RoutePath.EditCategory,
        element: <SettingEditCategoryPage/>,
      },
      {
        path: RoutePath.Stories,
        element: <SettingStoriesPage/>,
      },
      {
        path: RoutePath.TextBlocks,
        element: <SettingTextBlocksPage/>,
      },
      {
        path: RoutePath.Slider,
        element: <SettingSliderPage/>,
      },
      {
        path: RoutePath.BlocksOrder,
        element: <SettingBlocksOrder/>,
      },
      {
        path: RoutePath.Selections,
        element: <SelectionsPage/>,
      },
      {
        path: RoutePath.Payment,
        element: <PaymentPage/>,
      },
      {
        path: RoutePath.Delivery,
        element: <DeliveryPage/>,
      },
      {
        path: RoutePath.EditSelection,
        element: <EditSelectionPage/>,
      },
      {
        path: RoutePath.CreateSelection,
        element: <CreateSelectionPage/>,
      },
      {
        path: RoutePath.SelectionProducts,
        element: <SelectionProductPage/>,
      },
      {
        path: RoutePath.Subscription,
        element: <SubscriptionPage/>,
      },
      {
        path: RoutePath.StoreSetup,
        element: <StoreSetupPage/>,
      },
      {
        path: RoutePath.Integrations,
        element: <IntegrationsPage/>,
      },
      {
        path: RoutePath.IntegrationTelegram,
        element: <TelegramIntegrationPage/>,
      },
      {
        path: RoutePath.IntegrationProducts,
        element: <ProductsIntegrationPage/>,
      },
      {
        path: RoutePath.SelectColor,
        element: <SelectColorPage/>,
      },
      {
        path: RoutePath.OrderingAndDelivery,
        element: <OrderingAndDeliveryPage/>,
      },
      {
        path: RoutePath.Recommendation,
        element: <RecommendationsPage/>,
      },
      {
        path: RoutePath.Newsletters,
        element: <NewslettersPage/>,
      },
      {
        path: RoutePath.CreateNewsletter,
        element: <CreateNewsLetterPage/>,
      },
      {
        path: RoutePath.PromoCodes,
        element: <PromoCodesPage />
      },
      {
        path: RoutePath.CreatePromoCode,
        element: <CreatePromoCodePage />
      },
      {
        path: RoutePath.EditPromoCode,
        element: <EditPromoCodePage />
      },
      {
        path: '*',
        element: <NotFoundPage/>
      },
    ]
  },
  {
    path: RoutePath.Administrators,
    element: <ProtectedRoutes isManageAdmins={true}/>,
    children: [
      {
        path: RoutePath.Administrators,
        element: <ManageAdministratorsPage />,
      },
      {
        path: '*',
        element: <NotFoundPage/>
      },
    ],
  },
  {
    path: RoutePath.Wizard,
    element: <ProtectedRoutes isWizard={true}/>,
    children: [
      {
        path: RoutePath.Wizard,
        element: <WizardPage />,
      },
      {
        path: '*',
        element: <NotFoundPage/>
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage/>
  },
];

import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ImageLoaderRules } from './ImageLoader.style';
import Text from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IProps {
  name: string;
  imageUrl?: string;
  message?: string;
  propsStyles: IStyles;
  onChangeFile?: (file: File) => void;
  extend?: string;
  labelText?: string;
  isError?: boolean;
  checkSize?: {
    maxWidth: number;
    maxHeight: number;
  }
}

interface IStyles {
  width: number;
  height: number;
  borderRadius: string;
}

export const ImageLoader: FC<IProps> = memo(function ImageLoader(props) {
  const {
    imageUrl,
    name,
    message,
    propsStyles,
    extend,
    onChangeFile,
    labelText,
    checkSize,
    isError
  } = props;
  const [error, setError] = useState(false);
  const { css } = useStyle(ImageLoaderRules, {
    width: propsStyles.width,
    height: propsStyles.height,
    borderRadius: propsStyles.borderRadius,
    error,
  });

  const [selectedImgUrl, setSelectedImgUrl] = useState('');

  const {t} = useTranslation();

  useEffect(() => {
    if (isError) {
      setError(isError)
    }
  }, [isError]);

  useEffect(() => {
    if (imageUrl) {
      setSelectedImgUrl(imageUrl);
    }
  }, [imageUrl]);

  function checkImageSize(file) {
    if (!file.type.startsWith('image/') || !checkSize) return
    const {maxWidth, maxHeight} = checkSize;
    const reader = new FileReader();
    reader.onload = function(e) {
      const img = new Image();
      img.onload = function () {
        if(img.naturalWidth < maxWidth || img.naturalHeight < maxHeight) {
          setError(true);
          onChangeFile(null);
        } else {
          setError(false);
          onChangeFile(file);
        }
      }
      img.src = String(e.target.result);
    }
    reader.readAsDataURL(file);
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const img = event.target.files[0];
    if (!img) return;
    const url = URL.createObjectURL(img);
    setSelectedImgUrl(url);

    if(checkSize) {
      checkImageSize(img)
    }
    else if(onChangeFile) {
      onChangeFile(img);
    }
  }

  return (
    <label htmlFor={name} className={[css.imageLoader, extend].join(' ')}>
      <input
        type="file"
        name={name}
        id={name}
        accept=".png, .jpg, .jpeg, .webp"
        onChange={onChange}
      />
      <div className={css.image} style={{ backgroundImage: `url(${selectedImgUrl})` }}>
        {!selectedImgUrl && <Icon name="plus" />}
      </div>
      <Text
        text={labelText ?? t('imageLoader.uploadImage')}
        mod="text"
        extend={css.labelText}
        fontSize={12}
        lineHeight={12}
        fontWeight={700}
      />
      {message && (
        <Text text={message} mod="text" fontSize={12} lineHeight={14} extend={css.message} />
      )}
    </label>
  );
});

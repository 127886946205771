import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalImportProductsKeys {
  modalInner: {};
  header: {};
  footer: {};
  btnWrapper: {};
  input: {};
  invalidMessage: {};
  spinnerWrapper: {};
}

interface IModalImportProductsProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalImportProducts = IRuleFn<IModalImportProductsKeys, IModalImportProductsProps>;

const ModalImportProductsRuleFn: IModalImportProducts = props => {
  const { sidePadding, colorWhite, colorRed } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      ...padding(12, 0, `${footerHeight}px`),
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(14, 0, 5),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    btnWrapper: {
      ...padding(24, 0, 8),
    },
    input: {
      display: 'none',
    },
    invalidMessage: {
      marginTop: 12,
      color: colorRed,
    },
    spinnerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...padding(90, 0),
    }
  };
};

export const ModalImportProductsRules = createCachedStyle(ModalImportProductsRuleFn);

import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ListButtonRuleKeys {
  isActive: {};
  list: {};
  rightBlock: {};
  color: {};
  redColor: {};
  title: {};
}

interface ListButtonRuleProps {
  disabled: boolean;
  bg: string;
  padding?: string;
  titleUppercase?: boolean;
  titleMarginBottom?: number;
}

export type ListButtonRules = IRuleFn<ListButtonRuleKeys, ListButtonRuleProps>;

const listButtonRuleFn: ListButtonRules = props => {
  const { colorWhite, colorBlack, colorRed } = props.theme;
  const { disabled, bg, padding, titleUppercase, titleMarginBottom } = props;

  return {
    list: {
      pointerEvents: disabled ? 'none' : 'auto',
      padding: padding || 0,
      opacity: disabled ? 0.5 : 1,
      backgroundColor: bg ? bg : colorWhite,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '9px',
      textTransform: titleUppercase ? 'uppercase' : 'none',
      '& *': {
        pointerEvents: disabled ? 'none' : 'auto',
      }
    },
    title: {
      marginBottom: titleMarginBottom
    },
    isActive: {
      flexShrink: 0,
      borderRadius: 0,

      '& svg': {
        flexShrink: 0,
        fill: colorBlack,
      },
    },
    rightBlock: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },
    color: {
      width: 20,
      height: 20,
      borderRadius: '50%'
    },
    redColor: {
      color: colorRed
    },
  };
};

export const listButtonRules = createCachedStyle(listButtonRuleFn);

import { Middleware } from '@reduxjs/toolkit';
import { CustomErrorType, setCustomErrorFromErrorMiddleware } from '../slices/modalErrorSlice';
import { findEnumValueByPartialMatch } from 'src/utils/findEnumValueByPartialMatch';
import { AuthenticationError } from '@teleport/schemas-protobuf';

const errorMiddleware: Middleware = storeAPI => next => (action:any) => {
  if (action.type.endsWith('/rejected')) {
    const state = storeAPI.getState();
    if (findEnumValueByPartialMatch(action.error.message, AuthenticationError) === AuthenticationError.UNAUTHENTICATED && state.config?.config?.toggles?.authCustomErrorEnabled) {
      storeAPI.dispatch(setCustomErrorFromErrorMiddleware(CustomErrorType.AuthenticationError));
    }
  }
  return next(action);
};

export default errorMiddleware;

import { render } from './Render';
import { getConfig } from './utils/config/getConfig';
import { getTranslation } from './utils/i18n/getTranslation';
import { getTelegramLanguage } from './utils/i18n/helpers/getTelegramLanguage';
import { Language } from './utils/i18n/i18n.interface';

export async function init() {
  const telegramLanguage: Language = getTelegramLanguage();
  const config = await getConfig();
  const translation = await getTranslation(telegramLanguage);
  const translationRu = telegramLanguage !== Language.ru ? await getTranslation(Language.ru) : undefined;

  render(config, translation,translationRu, telegramLanguage);
}

import {NetworkStatus} from '../../utils/network/network.constant';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {createAppAsyncThunk} from '../../hooks/redux';
import {PaymentMethodTranslator} from '../translators/paymentMethodsTranslator';

export enum PaymentCases {
  customMethod = 'customMethod',
}

interface IPaymentMethodCustom {
  uuid: string;
  name: string;
  description: string;
}

export type IPaymentMethod = {
  value: IPaymentMethodCustom;
  case: PaymentCases;
};

interface IPaymentMethodSlice {
  paymentMethods: IPaymentMethod[];
  getListNetworkStatus: NetworkStatus,
  createNetworkStatus: NetworkStatus,
  upsertNetworkStatus: NetworkStatus,
  deleteNetworkStatus: NetworkStatus,
  errorText?: string;
}

const initialState: IPaymentMethodSlice = {
  paymentMethods: [],
  getListNetworkStatus: NetworkStatus.None,
  createNetworkStatus: NetworkStatus.None,
  upsertNetworkStatus: NetworkStatus.None,
  deleteNetworkStatus: NetworkStatus.None,
}

export const paymentMethodList = createAppAsyncThunk(
  'PaymentMethod/paymentMethodList',
  async (_, thunkAPI) => {
    const response = await thunkAPI.extra.portAdminApi().paymentMethodList({});
    return PaymentMethodTranslator.fromPaymentMethodListResponse(response);
  },
);

export const createPaymentMethod = createAppAsyncThunk(
  'PaymentMethod/createPaymentMethod',
  async (paymentMethod: IPaymentMethod, thunkAPI) => {
    const requestData = PaymentMethodTranslator.toPaymentMethodUpsertRequest(paymentMethod)
    const result = await thunkAPI.extra.portAdminApi().paymentMethodUpsert(requestData)
    const paymentMethodData: IPaymentMethod = {
      value: {
        ...paymentMethod.value,
        uuid: result.paymentMethodUuid,
      },
      case: paymentMethod.case,
    }
    return paymentMethodData
  },
);

export const upsertPaymentMethod = createAppAsyncThunk(
  'PaymentMethod/upsertPaymentMethod',
  async (paymentMethod: IPaymentMethod, thunkAPI) => {
    const requestData = PaymentMethodTranslator.toPaymentMethodUpsertRequest(paymentMethod)
    await thunkAPI.extra.portAdminApi().paymentMethodUpsert(requestData)
    const paymentMethodData: IPaymentMethod = {
      value: {
        ...paymentMethod.value,
      },
      case: paymentMethod.case,
    }
    return paymentMethodData
  },
);

export const paymentMethodDelete = createAppAsyncThunk(
  'PaymentMethod/deletePaymentMethod',
  async (paymentMethodUuid: string, thunkAPI) => {
    await thunkAPI.extra.portAdminApi().paymentMethodDelete({
      paymentMethodUuid
    })
    return paymentMethodUuid
  },
);

export const paymentMethodSlice = createSlice({
  name: 'PaymentMethod',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'getListNetworkStatus' | 'createNetworkStatus' | 'upsertNetworkStatus' | 'deleteNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(createPaymentMethod.pending, state => {
      state.errorText = '';
      state.createNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createPaymentMethod.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.createNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(createPaymentMethod.fulfilled, (state, action) => {
      state.createNetworkStatus = NetworkStatus.Done;
      state.paymentMethods.push(action.payload)
    });

    builder.addCase(upsertPaymentMethod.pending, state => {
      state.errorText = '';
      state.upsertNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(upsertPaymentMethod.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(upsertPaymentMethod.fulfilled, (state, action) => {
      const paymentMethod = action.payload;
      const element = state.paymentMethods.find(el => el.value.uuid === paymentMethod.value.uuid);
      const index = state.paymentMethods.indexOf(element);
      if (index !== -1) {
        state.paymentMethods.splice(index, 1, paymentMethod);
      }
      state.upsertNetworkStatus = NetworkStatus.Done;
    });

    builder.addCase(paymentMethodDelete.pending, state => {
      state.errorText = '';
      state.deleteNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(paymentMethodDelete.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.deleteNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(paymentMethodDelete.fulfilled, (state, action) => {
      state.paymentMethods = state.paymentMethods.filter(method => method.value.uuid !== action.payload)
      state.deleteNetworkStatus = NetworkStatus.Done;
    });

    builder.addCase(paymentMethodList.pending, state => {
      state.errorText = '';
      state.getListNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(paymentMethodList.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.getListNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(paymentMethodList.fulfilled, (state, action) => {
      state.getListNetworkStatus = NetworkStatus.Done;
      state.paymentMethods = action.payload;
    });
  },
});

export const { resetNetworkStatus } = paymentMethodSlice.actions;

export const paymentMethodSelector = (state: RootState) => state[paymentMethodSlice.name];

import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreContext } from '@teleport/schemas-protobuf/hub/v1/hub_wizard_models_pb';
import { createAppAsyncThunk } from '../../hooks/redux';
import {transferOwnership} from './administratorsSlice';
import {UserRole} from '@teleport/schemas-protobuf';

interface IStoriesSlice {
  stores: StoreContext[];
  networkStatus: NetworkStatus;
  setActiveStoreNetworkStatus: NetworkStatus,
  createStoreNetworkStatus: NetworkStatus,
}

const initialState: IStoriesSlice = {
  stores: [],
  networkStatus: NetworkStatus.None,
  setActiveStoreNetworkStatus: NetworkStatus.None,
  createStoreNetworkStatus: NetworkStatus.None,
};

export const getStoreList = createAppAsyncThunk(
  'multipleStore/getStoreList',
  async (_, thunkAPI) => {
    return await thunkAPI.extra.hubApi.availableStoreList({})
  },
);

export const setActiveStore = createAppAsyncThunk(
  'multipleStore/setActiveStore',
  async (params: {storeUuid?: string}, thunkAPI) => {
    const requestParams = params.storeUuid ? params : {};
    await thunkAPI.extra.hubApi.setActiveStore(requestParams)
    return params.storeUuid
  },
);

export const createNewStore = createAppAsyncThunk(
  'multipleStore/createNewStore',
  async (_, thunkAPI) => {
    await thunkAPI.extra.hubApi.setActiveStore({})
  },
);

export const multipleStoreSlice = createSlice({
  name: 'MultipleStore',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'networkStatus' | 'setActiveStoreNetworkStatus' | 'createStoreNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStoreList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getStoreList.fulfilled, (state, action) => {
      state.stores = action.payload.stores;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStoreList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(setActiveStore.pending, state => {
      state.setActiveStoreNetworkStatus = NetworkStatus.Loading
    })
    builder.addCase(setActiveStore.rejected, state => {
      state.setActiveStoreNetworkStatus = NetworkStatus.Failed
    })
    builder.addCase(setActiveStore.fulfilled, (state, action) => {
      const activeStoreUuid = action.payload;
      state.stores = state.stores.map(el => {
        if(el.store.uuid === activeStoreUuid) {
          return {...el, active: true }
        }
        return {...el, active: false }
      })
      state.setActiveStoreNetworkStatus = NetworkStatus.Done
    })

    builder.addCase(createNewStore.pending, state => {
      state.createStoreNetworkStatus = NetworkStatus.Loading;
    })
    builder.addCase(createNewStore.rejected, state => {
      state.createStoreNetworkStatus = NetworkStatus.Failed;
    })
    builder.addCase(createNewStore.fulfilled, state => {
      state.createStoreNetworkStatus = NetworkStatus.Done;
    })

    builder.addCase(transferOwnership.fulfilled, state => {
      state.stores = state.stores.map(el => {
        if (el.userRole === UserRole.OWNER) {
          return {
            ...el,
            userRole: UserRole.ADMIN
          }
        }
        return el
      })
    })

  },
});

export const { resetNetworkStatus } = multipleStoreSlice.actions;

export const multipleStoreState = (state: RootState) => state[multipleStoreSlice.name];

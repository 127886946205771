import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SettingStoriesPageRules } from './SettingStoriesPage.style';
import { Layout } from 'src/components/Layout/Layout';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { useModalState } from 'src/hooks/useModalState';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { StoriesWrapper } from 'src/blocks/settings-main/setting-stories/StoriesWrapper/StoriesWrapper';
import { ModalSettingStory } from 'src/blocks/settings-main/setting-stories/ModalSettingStory/ModalSettingStory';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { storiesState } from 'src/redux/slices/storiesSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getStories } from 'src/redux/api/stories/getStories';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

export const SettingStoriesPage: FC = memo(function SettingStoriesPage(props) {
  const {} = props;
  const { css } = useStyle(SettingStoriesPageRules);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader, showLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { networkStatus, stories } = useAppSelector(storiesState);

  const { t } = useTranslation();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getStories());
    }
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, showLoader, hideLoader, dispatch]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  return (
    <Layout
      mainFlexColumn={true}
      padding={'4px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('settingStoriesPage.stories')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('settingStoriesPage.createStory')}
            propsStyles={{ width: '100%' }}
            onClick={openModal}
          />
        </Footer>
      }
    >
      
      {stories.length > 0 ? (
        <Container extend={css.container}>
          <StoriesWrapper stories={stories}/>
        </Container>
      ) : (
        <NoSettings
          title={t('settingStoriesPage.youHaventCreatedAnyStoriesYet')}
          text={t('settingStoriesPage.storiesAreSmallVerticalImagesPlacedAtTheTopOfTheHomepageClickingOnThemOpensTheLinkYouveSet')}
          icon="stories-large"
        />
      )}
      
      {renderModal && <ModalSettingStory active={activeModal} onClose={closeModal}/>}
    </Layout>
  );
});

import {IPaymentMethod, PaymentCases} from '../slices/paymentMethodsSlice';
import {
  PaymentMethodListResponse,
  PaymentMethodUpsertRequest
} from '@teleport/schemas-protobuf/port/v1/port_grpc_admin_pb';

export const PaymentMethodTranslator = {
  fromPaymentMethodListResponse(response: PaymentMethodListResponse): IPaymentMethod[]  {
    return response.paymentMethods.map(el => {
      switch (el.paymentMethod.case) {
        case PaymentCases.customMethod:
          return {
            value: {
              uuid: el.paymentMethod.value.uuid,
              name: el.paymentMethod.value.name,
              description: el.paymentMethod.value.description,
            },
            case: PaymentCases.customMethod
          }
        default:
          return undefined
      }
    }).filter(Boolean)
  },
  toPaymentMethodUpsertRequest(paymentMethod: IPaymentMethod): PaymentMethodUpsertRequest {
    switch (paymentMethod.case) {
      case PaymentCases.customMethod:
        return new PaymentMethodUpsertRequest({
          paymentMethod: {
            paymentMethod: {
              value: {
                uuid: paymentMethod.value.uuid ?? '',
                name: paymentMethod.value.name,
                description: paymentMethod.value.description,
              },
              case: paymentMethod.case
            }
          }
        })
    }
  }
};

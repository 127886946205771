import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalModifierKeys {
  header: {};
  modalInner: {};
  footer: {};
  grid: {};
  justifyWrapper: {};
}

interface IModalModifierProps {
  headerHeight: number;
}

export type IModalModifier = IRuleFn<IModalModifierKeys, IModalModifierProps>;

const ModalSaveUploadedProductsRuleFn: IModalModifier = props => {
  const { headerHeight } = props;
  const {sidePadding, colorWhite } = props.theme;

  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      ...padding(15, sidePadding, 20),
    },
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: 122,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    grid: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginTop: 4,
      ...padding(12, 0),
    },
  };
};

export const ModalSaveUploadedProductsRules = createCachedStyle(ModalSaveUploadedProductsRuleFn);

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { IntegrationTranslator } from '../translators/integrationTranslator';

interface IIntegrationSlice {
  networkStatus: NetworkStatus;
  saveNetworkStatus: NetworkStatus;
  integration: IIntegrationData
  errorText: string
}

export enum IntegrationType {
  none = 'none',
  telegram = 'telegram',
  products = 'products'
}

export interface IIntegrationData {
  channelLink: string
  messageText: string
  buttonText: string

}

const initialState: IIntegrationSlice = {
  networkStatus: NetworkStatus.None,
  saveNetworkStatus: NetworkStatus.None,
  errorText: '',
  integration: {
    channelLink: '',
    messageText: '',
    buttonText: ''
  }
};

export const getChannelIntegrationDetail = createAppAsyncThunk(
  'getChannelIntegrationDetail',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().channelIntegrationDetail({});
    return result
  },
);

export const channelIntegrationUpsertRequest = createAppAsyncThunk(
  'channelIntegrationUpsertRequest',
  async (data: IIntegrationData, thunkAPI) => {
    const integrationData = IntegrationTranslator.toChannelIntegrationRequest(data);
    await thunkAPI.extra.portOwnerApi().channelIntegrationUpsert(integrationData);
    return data
  },
);

export const integrationTelegramSlice = createSlice({
  name: 'IntegrationTelegram',
  initialState,
  reducers: {
    resetIntegrationError: state => {
      state.errorText = ''
      state.saveNetworkStatus = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(getChannelIntegrationDetail.pending, state => {
      state.errorText = ''
      state.networkStatus = NetworkStatus.Loading;
    })
    builder.addCase(getChannelIntegrationDetail.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done;
      state.integration = {
        channelLink: action.payload.integration?.channelLink,
        messageText: action.payload.integration?.messageText,
        buttonText: action.payload.integration?.buttonText,
      }
    })
    builder.addCase(getChannelIntegrationDetail.rejected, (state, action) => {
      state.errorText = action.error?.message?.length > 0 ? action.error.message : 'Channel integration error';
      state.networkStatus = NetworkStatus.Failed;
    })


    
    builder.addCase(channelIntegrationUpsertRequest.pending, state => {
      state.saveNetworkStatus = NetworkStatus.Loading;
      state.errorText = ''
    })
    builder.addCase(channelIntegrationUpsertRequest.fulfilled, (state, action) => {
      state.saveNetworkStatus = NetworkStatus.Done;
      state.integration = {
        channelLink: action.payload.channelLink,
        messageText: action.payload.messageText,
        buttonText: action.payload.buttonText,
      }
    })
    builder.addCase(channelIntegrationUpsertRequest.rejected, (state, action) => {
      state.errorText = action.error?.message?.length > 0 ? action.error.message : 'Channel integration error';
      state.saveNetworkStatus = NetworkStatus.Failed;
    })
  }
});

export const { resetIntegrationError } = integrationTelegramSlice.actions;

export const integrationState = (state: RootState) => state[integrationTelegramSlice.name];

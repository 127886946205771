import { FC, memo, useEffect, useState } from 'react';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { selectColorPageRules } from './SelectColorPage.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { wizardStateSelector, setAccentColor, resetNetworkStatus } from 'src/redux/slices/wizardSlice';
import { configState, togglesState } from 'src/redux/slices/configSlice';
import useLoader from 'src/hooks/useLoader';
import { AccentColor } from 'src/components/AccentColor/AccentColor';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { openErrorModal } from 'src/redux/slices/modalErrorSlice';

export const SelectColorPage: FC = memo(function SelectColorPage() {
  const { hideLoader } = useLoader();
  const { css } = useStyle(selectColorPageRules);
  const { config } = useAppSelector(configState);
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showBackendErrors } = useAppSelector(togglesState);
  const { t } = useTranslation()
  const [previousColor, setPreviousColor] = useState<string | undefined>()

  const changeAccentColor = (color: string) => {
    setPreviousColor(wizardData.accentColor || config.accentColor)
    dispatch(setAccentColor(color));
  };

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/store-setup');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { wizard: wizardData, networkStatus, saveNetworkStatus, saveStateError } = useAppSelector(wizardStateSelector);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader]);

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Failed) {
      dispatch(resetNetworkStatus('saveNetworkStatus'))
      dispatch(openErrorModal({
        errorTitle: showBackendErrors ?  saveStateError : t('wizardPage.failedToUpdateTheStore'),
      }))
      dispatch(setAccentColor(previousColor));
    }
  }, [saveNetworkStatus, dispatch, previousColor, saveStateError, showBackendErrors, t])

  useEffect(() => {
    dispatch(resetNetworkStatus('saveNetworkStatus'))
  }, [dispatch])

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setSavedSuccessfully(true);
      setTimeout(() => setSavedSuccessfully(false), 1500);
    }
  }, [saveNetworkStatus]);

  const saveColorFunction = () => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizardData },
      true,
    );

    dispatch(wizardSaveState({ requestData: wizardStateRequest }));
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text={t('selectColorPage.color')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
        </header>
      }
      footer={
        <Footer>
          <Button
            disabled={saveNetworkStatus === NetworkStatus.Loading || savedSuccessfully}
            successfully={savedSuccessfully && networkStatus !== NetworkStatus.Loading}
            onClick={saveColorFunction}
            text={t('selectColorPage.save')}
            propsStyles={{ width: '100%' }}
          />
        </Footer>
      }
    >
      <Container>
        <div className={css.content}>
          <AccentColor
            setAccentColor={changeAccentColor}
            accentColor={wizardData.accentColor || config.accentColor}
          />
        </div>
      </Container>
    </Layout>
  );
});

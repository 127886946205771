import { FC, ReactNode, useEffect, useRef } from 'react';
import ReactPortal from './ReactPortal';
import { useStyle } from 'src/utils/theme/useStyle';
import { modalRules } from './modal.style';
import { useScrollBlock } from 'src/hooks/useScrollBlock';

interface IProp {
  children?: ReactNode;
  active: boolean;
  setActive?: any;
  name: ModalNames;
  deep?: number;
  propsStyles?: IStyles;
  noCloseBar?: boolean;
  expand?: boolean;
  onClose: () => void;
}

interface IStyles {
  paddingTop?: number;
  paddingRight?: number;
  paddingLeft?: number;
  paddingBottom?: number;
  backgroundColor?: string;
}

export enum ModalNames {
  Sort = 'Sort',
  Filters = 'Filters',
  PromoCode = 'PromoCode',
  Success = 'Success',
  Error = 'Error',
  Rates = 'Rates',
  List = 'List',
  Color = 'Color',
  SettingStory = 'SettingStory',
  ModifierType = 'ModifierType',
  ModifierVariants = 'ModifierVariants',
  Universal = 'Universal',
  SettingProducts = 'SettingProducts',
  AccentBackground = 'AccentBackground',
  SettingsSelection = 'SettingSelection',
  SelectSelection = 'SelectSelection',
  StoreCreatedResult = 'StoreCreatedResult',
  StoreChangesSaved = 'StoreChangesSaved',
  SaveUploadedProducts = 'SaveUploadedProducts',
  ImportProducts = 'ImportProducts',
}

const Modal: FC<IProp> = props => {
  const { children, active, name, noCloseBar, deep = 1, expand, onClose, propsStyles } = props;
  const [blockScroll, allowScroll] = useScrollBlock();
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const modalCloseBarRef = useRef<HTMLDivElement>(null);
  const modalTitleRef = useRef<HTMLParagraphElement>(null);

  const { css } = useStyle(modalRules, {
    active,
    paddingTop: propsStyles?.paddingTop,
    paddingRight: propsStyles?.paddingRight,
    paddingLeft: propsStyles?.paddingLeft,
    paddingBottom: propsStyles?.paddingBottom,
    backgroundColor: propsStyles?.backgroundColor,
    titleHeight: modalTitleRef.current?.clientHeight,
    expand,
    deep,
  });

  useEffect(() => {
    const modalContent = modalContentRef.current;
    const modalCloseBar = modalCloseBarRef.current;
    let startClientY = 0;

    if (modalCloseBar) {
      modalCloseBar.addEventListener('pointerdown', startSwipe);
      modalCloseBar.ondragstart = function () {
        return false;
      };
      // pointercancel
    }

    function startSwipe(event) {
      startClientY = event.clientY;
      let translateY;

      modalCloseBar.setPointerCapture(event.pointerId);

      // Блокируем событие
      if (event.target.closest('.preventDefault')) {
        return;
      }

      function move(event) {
        const clientY = event.clientY;
        translateY = clientY - startClientY;

        if (translateY < 0) {
          modalContent.style.transform = 'translateY(0px)';
        } else {
          modalContent.style.transform = `translateY(${translateY}px)`;
        }
      }

      function pointerUp() {
        if (translateY > modalContent.clientHeight / 2) {
          modalContent.style.transform = `translateY(${modalContent.clientHeight}px)`;
          setTimeout(onClose, 100);
        } else {
          modalContent.style.transform = 'translateY(0px)';
        }
        modalCloseBar.removeEventListener('pointermove', move);
        modalCloseBar.removeEventListener('pointerup', pointerUp);
      }

      modalCloseBar.addEventListener('pointermove', move);
      modalCloseBar.addEventListener('pointerup', pointerUp);
    }

    return function () {
      if (modalCloseBar) {
        modalCloseBar.removeEventListener('pointerdown', startSwipe);
      }
    };
  }, [modalContentRef, onClose, active]);

  // Закрытие модалки при клике по тёмной области
  useEffect(() => {
    function handleClick(e: Event) {
      if (e.target === modalRef.current) {
        onClose();
      }
    }

    if (active) {
      blockScroll();
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
      allowScroll();
    };
  }, [active, onClose, allowScroll, blockScroll]);

  return (
    <ReactPortal portalID={name}>
      <div className={css.modal} ref={modalRef}>
        <div className={`${css.modalContent} modalContent`} ref={modalContentRef}>
          {!noCloseBar && <div className={css.closeBar} ref={modalCloseBarRef}></div>}
          {children}
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;

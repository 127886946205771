import { ReactNode, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import { modalSuccessRules } from './ModalSuccess.style';
import { ReactComponent as SuccesIcon } from 'src/assets/svg/shield-tick.svg';
import Text from 'src/components/UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';

interface IProps {
  onClose: () => void;
  active: boolean;
  title: string;
  text?: string;
  children?: ReactNode;
  buttonText?: string
  buttonCoor?: 'colorBlueDark'
}

const ModalSuccess = (props: IProps) => {
  const { active, title, text, buttonText, onClose, buttonCoor } = props;
  const { theme } = useTheme();
  const { css } = useStyle(modalSuccessRules);

  const closeModalFn = () => {
    onClose();
  };
  return (
    <Modal active={active} name={ModalNames.Success} onClose={onClose}>
      <div className={css.content}>
        <SuccesIcon />
        <div>
          <Text
            mod={'text'}
            text={title}
            fontSize={20}
            lineHeight={26}
            fontWeight={800}
          />
          {text && (
            <Text
              mod={'text'}
              extend={css.text}
              text={text}
              fontSize={14}
              lineHeight={18}
              fontWeight={500}
            />
          )}
        </div>
        {props.children}
      </div>
      <footer className={css.footer}>
        <Button
          onClick={closeModalFn}
          text={buttonText? buttonText : 'Хорошо'}
          propsStyles={{
            width: '100%',
            background: theme[buttonCoor]
          }}
        />
      </footer>
    </Modal>
  );
};

export default memo(ModalSuccess);

import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { addPx, padding } from 'src/utils/applyCSSProperty ';

export interface IModalNotificationRuleKeys {
  content: {};
  title: {};
  text: {};
  footer: {};
}

export type IModalNotificationRules = IRuleFn<IModalNotificationRuleKeys>;

const modalNotificationRuleFn: IModalNotificationRules = () => {
  return {
    content: {
      ...padding(48, 0),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 64,
      alignItems: 'center',
    },
    footer: {
      display: 'flex',
      columnGap: '12px',
    },
    title: {
      fontWeight: '800',
      fontSize: 20,
      lineHeight: '26px',
      marginBottom: 8,
    },
    text: {
      fontWeight: '500',
      fontSize: 14,
      lineHeight: addPx(18),
    },
  };
};

export const modalNotificationRules = createCachedStyle(modalNotificationRuleFn);

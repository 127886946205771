import {useTranslation} from '../i18n/hooks/useTranslation';
import {useMemo} from 'react';

export const useFormatDate = (milliseconds: number) => {
  const { t } = useTranslation();

  return useMemo(() => {

    if (!milliseconds) return '';

    const date = new Date(milliseconds);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = `${date.getHours()}`.length === 1 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minutes = `${date.getMinutes()}`.length === 1 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;

    const nowDay = new Date().getDate();
    const nowMonth = new Date().getMonth() + 1;
    const nowYear = new Date().getFullYear();

    if (
      day === nowDay
      && month === nowMonth
      && year === nowYear
    ) {
      return `${t('date.today')} ${hours}:${minutes}`;
    }

    let monthInText = '';
    switch (month) {
      case 1:
        monthInText = t('date.january')
        break;
      case 2:
        monthInText = t('date.february')
        break;
      case 3:
        monthInText = t('date.march')
        break;
      case 4:
        monthInText = t('date.april')
        break;
      case 5:
        monthInText = t('date.may')
        break;
      case 6:
        monthInText = t('date.june')
        break;
      case 7:
        monthInText = t('date.july')
        break;
      case 8:
        monthInText = t('date.august')
        break;
      case 9:
        monthInText = t('date.september')
        break;
      case 10:
        monthInText = t('date.october')
        break;
      case 11:
        monthInText = t('date.november')
        break;
      case 12:
        monthInText = t('date.december')
        break;
    }

    return `${day} ${monthInText} ${year} ${hours}:${minutes}`;
  }, [milliseconds, t])

}

import {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useStyle} from '../../utils/theme/useStyle';
import {IIntegrationStatusRuleKeys, IntegrationStatusRules} from './IntegrationStatus.style';
import {Button} from '../../components/UI/Button/Button';
import {useTheme} from '../../utils/theme/useTheme';
import Text from '../../components/UI/Text/Text';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {togglesState} from '../../redux/slices/configSlice';
import {integrationProductsState, providerIntegrationSaveState} from '../../redux/slices/integrationProductsSlice';
import {Provider, ProviderSyncState} from '@teleport/schemas-protobuf';
import {IconList} from '../../components/UI/Icon/Icon';
import {useFormatDate} from '../../utils/hooks/useFormatDate';
import {NetworkStatus} from '../../utils/network/network.constant';
import {openErrorModal} from '../../redux/slices/modalErrorSlice';

interface IProps {
  extend?: Partial<IIntegrationStatusRuleKeys>;
  openIntegrationModal: () => void;
}

export const IntegrationStatus: FC<IProps> = memo(function IntegrationStatus(props) {
  const { extend, openIntegrationModal } = props;
  const { css } = useStyle(IntegrationStatusRules, { extend });
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { wbIntegrationEnabled, ozonIntegrationEnabled, bitrixIntegrationEnabled } = useAppSelector(togglesState);
  const { syncState, provider, lastSyncedAt, changeSyncStateNetworkStatus } = useAppSelector(integrationProductsState);
  const dispatch = useAppDispatch();
  const [icon, setIcon] = useState<IconList>('play');
  const [clickFunction, setClickFunction] = useState(() => openIntegrationModal);
  const [buttonBackground, setButtonBackground] = useState<string>(theme.colorGrayLight);
  const [statusTitle, setStatusTitle] = useState(t('integrationStatus.notConfigured'));
  const date = useFormatDate(lastSyncedAt);
  const activateIntegration = useCallback(()  => {
    dispatch(providerIntegrationSaveState(ProviderSyncState.ACTIVE))
  }, [dispatch]);

  const pauseIntegration = useCallback(() => {
    dispatch(providerIntegrationSaveState(ProviderSyncState.PAUSED))
  }, [dispatch]);

  const syncText = useMemo(() => {
    if (syncState === ProviderSyncState.UNSPECIFIED) {
      let text = t('integrationStatus.setUpSynchronizationWith');

      if (wbIntegrationEnabled) {
        text = text + ` ${t('modalChooseIntegrationService.wb')}`
      }
      if (ozonIntegrationEnabled && wbIntegrationEnabled) {
        text = text + `, ${t('modalChooseIntegrationService.ozon')}`
      } else if (ozonIntegrationEnabled) {
        text = text + ` ${t('modalChooseIntegrationService.ozon')}`
      }
      if (ozonIntegrationEnabled && bitrixIntegrationEnabled) {
        text = text + ` ${t('integrationStatus.or')} ${t('modalChooseIntegrationService.bitrix')}`
      } else if (wbIntegrationEnabled && bitrixIntegrationEnabled) {
        text = text + ` ${t('integrationStatus.or')} ${t('modalChooseIntegrationService.bitrix')}`
      } else if (bitrixIntegrationEnabled) {
        text = text + ` ${t('modalChooseIntegrationService.bitrix')}`
      }
      return text
    }

    let lastUpdateText = t('integrationStatus.lastUpdate');
    if (provider === Provider.WILDBERRIES) {
      lastUpdateText = lastUpdateText + ` ${t('modalChooseIntegrationService.wb')}:\n${date}`
    }
    if (provider === Provider.OZON) {
      lastUpdateText = lastUpdateText + ` ${t('modalChooseIntegrationService.ozon')}:\n${date}`
    }
    if (provider === Provider.BITRIX) {
      lastUpdateText = lastUpdateText + ` ${t('modalChooseIntegrationService.bitrix')}:\n${date}`
    }
    return lastUpdateText;

  }, [
    t,
    date,
    provider,
    bitrixIntegrationEnabled,
    ozonIntegrationEnabled,
    wbIntegrationEnabled,
    syncState
  ])

  useEffect(() => {
    if (syncState === ProviderSyncState.UNSPECIFIED) {
      setIcon('play');
      setButtonBackground(theme.colorGrayLight);
      setClickFunction(() => openIntegrationModal);
      setStatusTitle(t('integrationStatus.notConfigured'));
      return;
    }
    if (syncState === ProviderSyncState.PAUSED) {
      setIcon('play');
      setButtonBackground(theme.colorBlue);
      setClickFunction(() => activateIntegration);
      setStatusTitle(t('integrationStatus.paused'));
      return;
    }
    if (syncState === ProviderSyncState.ACTIVE) {
      setIcon('pause');
      setButtonBackground(theme.colorGreen);
      setClickFunction(() => pauseIntegration);
      setStatusTitle(t('integrationStatus.active'));
      return;
    }
  }, [
    activateIntegration,
    openIntegrationModal,
    pauseIntegration,
    t,
    theme,
    syncState
  ]);

  useEffect(() => {
    if (changeSyncStateNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('integrationStatus.failedChangeStatus')
      }))
    }
  }, [
    dispatch,
    t,
    changeSyncStateNetworkStatus
  ]);

  return (
    <div className={css.root}>
      <Button
        icon={icon}
        propsStyles={{
          padding: 5,
          width: 100,
          height: 100,
          borderRadius: '50%',
          background: buttonBackground,
        }}
        hasGradient={false}
        onClick={clickFunction}
        extend={css.button}
      />
      <Text
        text={statusTitle}
        mod='title'
        fontSize={16}
        fontWeight={700}
        lineHeight={18}
        extend={css.title}
      />
      <Text
        mod='text'
        text={syncText}
        fontWeight={400}
        fontSize={14}
        lineHeight={18}
        extend={css.info}
      />
    </div>
  )
})

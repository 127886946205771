import { FC, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { modalOzonRules } from './ModalOzon.style';
import { Input } from '../UI/Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useTheme } from 'src/utils/theme/useTheme';
import { Link } from 'react-router-dom';
import { Button } from '../UI/Button/Button';
import { ProviderIntegrationCase, providerIntegrationUpsert } from 'src/redux/slices/integrationProductsSlice';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { useAppDispatch } from 'src/hooks/redux';

interface IProps {
  active: boolean; 
  onClose: () => void; 
  onSubmitCallback: (provider: ProviderIntegrationCase) => void 
}

interface IFormWb {
  clientId: string;
  apiKey: string;
}

export const ModalOzon: FC<IProps> = memo(function ModalOzon(props) {
  const { onClose, onSubmitCallback } = props;
  const { theme } = useTheme();
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLFormElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalOzonRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });

  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({
    defaultValues: {
      clientId: '',
      apiKey: '',
    },
  });

  const onSubmit: SubmitHandler<IFormWb> = data => {
    onSubmitCallback(ProviderIntegrationCase.Ozon)
    dispatch(providerIntegrationUpsert({
      provider: {
        value: {
          clientId: data.clientId,
          apiKey: data.apiKey,
        },
        case: ProviderIntegrationCase.Ozon
      }
    }))
  };

  const { t } = useTranslation();

  // scroll modal on focus
  useScrollModal(modalInnerRef);

  return (
    <Modal
      name={ModalNames.ModifierType}
      active={true}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalOzon.ozonConnection')} mod='title' fontSize={22} lineHeight={46}/>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} ref={modalInnerRef} className={css.modalInner}>
        <div className={css.formInner}>
          <div className={css.formInnerGrid}>
            <div>
              <Text text={'Client ID'} mod='text' fontWeight={700} fontSize={14} lineHeight={18}/>
              <Text text={t('modalOzon.copyTheClientIdInOzonSellerInTheApiKeysSection')} mod='text' extend={css.inputLabelText} fontWeight={400} fontSize={12} lineHeight={14}/>
              <Input
                controlled={false}
                {...register('clientId', {
                  required: t('modalOzon.requiredField'),
                })}
                type='text'
                maxLength={200}
                errorMessage={errors.clientId?.message}
                propsStyles={{backgroundColor: theme.background}}
                placeholder={t('modalOzon.insertClientIdFromOzon')}
              />
            </div>
            <div>
              <Text text={t('modalOzon.apiKey')} mod='text' fontWeight={700} fontSize={14} lineHeight={18}/>
              <Text text={t('modalOzon.generateAkeyInOzonSellerInTheApiKeysSection')} mod='text' extend={css.inputLabelText} fontWeight={400} fontSize={12} lineHeight={14}/>
              <Input
                controlled={false}
                {...register('apiKey', {
                  required: t('modalOzon.requiredField'),
                })}
                type='text'
                maxLength={200}
                errorMessage={errors.apiKey?.message}
                propsStyles={{backgroundColor: theme.background}}
                placeholder={t('modalOzon.insertApiKeyFromOzon')}
              />
            </div>
          </div>
          <Link className={css.link} to="#">
            <Text text={t('modalOzon.viewTheInstructions')} mod='text' fontWeight={600} extend={css.link}  fontSize={12} lineHeight={16}/>
          </Link>
        </div>
        <div className={css.footer}  ref={modalFooterRef}>
          <Button
            text={t('modalOzon.connect')}
            propsStyles={{ width: '100%' }}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
});

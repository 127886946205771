import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface INewsletterCardKeys {
  card: {};
  image: {};
  info: {};
  text: {};
  textInner: {};
  iconLink: {};
  iconBars: {};
  link: {};
  buttonDrag: {};
  fallbackSvg: {};
  showMore: {};
  iconChevron: {};
  time: {};
  countRow: {};
}

export interface INewsletterCardProps {
  isOpen: boolean;
}

export type INewsletterCard = IRuleFn<INewsletterCardKeys, INewsletterCardProps>;

const NewsletterCardRuleFn: INewsletterCard = props => {
  const { colorRgbBlack, colorDarkGrey, colorWhite, mainColor } = props.theme;
  const { isOpen } = props;

  return {
    info: {
      width: '100%',
    },
    card: {
      touchAction: 'manipulation',
      // touchAction: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      ...padding(12),
      display: 'flex',
      columnGap: 16,
      alignItems: 'center',
      backgroundColor: colorWhite,
      borderRadius: 12,
    },
    image: {
      flexShrink: 0,
      borderRadius: 4,
      objectFit: 'cover',
      width: 100,
      height: 100,
    },
    text: {
      marginTop: 4,
      marginBottom: 16,
      display: isOpen ? 'grid' : '-webkit-box!important',
      gridTemplateRows: isOpen ? '1fr' : '0fr',
      transition: 'grid-template-rows .2s easy',
      width: '100%',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis', 
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: -0.4,
    },
    textInner: {
      minHeight: 16,
    },
    iconLink: {
      width: 16,
      height: 16,
      fill: `rgba(${colorRgbBlack}, .5)`,
      flexShrink: 0,
    },
    link: {
      wordWrap: 'anywhere',
    },
    iconBars: {
      flexShrink: 0,
      width: 20,
      height: 20,
      fill: colorDarkGrey,
    },
    buttonDrag: {
      border: 'none',
      background: 'none',
      // width: 30,
      // height: 30,
      outline: 'none',
      touchAction: 'none',
    },
    fallbackSvg: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
    showMore: {
      background: 'none',
      padding: 'none',
      outline: 'none',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      color: mainColor,
      '& p': {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 500,
      }
    },
    iconChevron: {
      width: 12,
      height: 12,
      fill: mainColor,
      transition: 'transform .2s easy',
      transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'
    },
    time: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
      color: `rgba(${colorRgbBlack}, .5)`,
      textAlign: 'right',
    },
    countRow: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
      color: `rgba(${colorRgbBlack}, .5)`,
    }
  };
};

export const NewsletterCardRules = createCachedStyle(NewsletterCardRuleFn);

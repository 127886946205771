import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import { modalNotificationRules } from './ModalNotification.style';
import { useTheme } from 'src/utils/theme/useTheme';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { Icon } from '../UI/Icon/Icon';

interface IProps {
  onClose: () => void;
  active: boolean;
  title: string;
  text?: string;
  btntxt?: string;
  btnFn?: () => void;
  deep?: number
}

const ModalNotification = (props: IProps) => {
  const { active, title, onClose, btntxt, text, deep } = props;
  const { theme } = useTheme();
  const { css } = useStyle(modalNotificationRules);

  const { t } = useTranslation()

  return (
    <Modal active={active} deep={deep} name={ModalNames.Error} onClose={onClose}>
      <div className={css.content}>
        <Icon name='notification' />
        <p className={css.title}>{title}</p>
        <p className={css.text}>{text}</p>
      </div>
      <footer className={css.footer}>
        <Button
          onClick={onClose}
          text={btntxt ?? t('modalNotification.okay')}
          propsStyles={{
            width: '100%',
            background: theme.colorBlack,
            color: theme.colorWhite
          }}
          hasGradient={false}
        />
      </footer>
    </Modal>
  );
};

export default memo(ModalNotification);

import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalWbKeys {
  modalInner: {};
  formInner: {};
  header: {};
  footer: {};
  link: {};
  inputLabelText: {};
}

interface IModalWbProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalWb = IRuleFn<IModalWbKeys, IModalWbProps>;

const ModalWbRuleFn: IModalWb = props => {
  const { sidePadding, colorWhite, colorBlueDark } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      position: 'relative',
      ...padding(12, sidePadding, `${footerHeight}px`)
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    wrapper: {
      ...padding(12, 0),
    },
    formInner: {
      paddingBottom: 30,
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      width: '100%',
      left: 0,
      bottom: 0,
      ...padding(12, sidePadding, 30),
    },
    link: {
      color: colorBlueDark,
      marginTop: 4,
      display: 'block',
    },
    inputLabelText: {
      marginBottom: 8
    }
  };
};

export const modalWbRules = createCachedStyle(ModalWbRuleFn);

export enum Toggles {
  loyaltyEnabled = 'loyaltyEnabled',
  multipleStoreEnabled = 'multipleStoreEnabled',
  promoCodeEnabled = 'promoCodeEnabled',
  newsletterEnabled = 'newsletterEnabled',
  administratorsEnabled = 'administratorsEnabled',
  showBackendErrors = 'showBackendErrors',
  ozonIntegrationEnabled = 'ozonIntegrationEnabled',
  wbIntegrationEnabled = 'wbIntegrationEnabled',
  bitrixIntegrationEnabled = 'bitrixIntegrationEnabled',
  productImportEnabled = 'productImportEnabled',
  wizardV1AppFocusSkip = 'wizardV1AppFocusSkip',
  wizardV1skipGeo = 'wizardV1skipGeo',
  authCustomErrorEnabled = 'authCustomErrorEnabled',
  paymentEnabled = 'paymentEnabled',
  deliveryEnabled = 'deliveryEnabled',

  // DEV TOGGLES:
  debugWizard = 'debugWizard',
}

export interface IRemoteConfig {
  router?: {
    'hub-api': string;
    'fs-api': string;
    'billing-api': string;
  };
  accentColor?: string;
  hubBotUsername?: string;
  portBotUsername?: string;
  typesProductFile?: string;
  toggles?: Record<Toggles, boolean>;
}

export interface IConfig {
  hubApiUrl?: string;
  fsApiUrl?: string;
  billingApiUrl?: string;
  accentColor?: string;
  hubBotUsername?: string;
  portBotUsername?: string;
  typesProductFile?: string[];
  toggles?: Record<Toggles, boolean>;
}

import {IRuleFn} from '../../utils/theme/fela.interface';
import {createCachedStyle} from '../../utils/theme/createCachedStyle';
import {padding} from '../../utils/applyCSSProperty ';

export interface IInfoCardRuleKeys {
  root: {};
  iconBlock: {}
  textWrapper: {};
}

interface IInfoCardProps {
  extend?: Partial<IInfoCardRuleKeys>
  iconBlockColor: string;
}

export type IInfoCardRules = IRuleFn<IInfoCardRuleKeys, IInfoCardProps>;

const InfoCardRuleFn: IInfoCardRules = props => {
  const { iconBlockColor } = props;

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 16,
    },
    iconBlock: {
      width: 60,
      height: 60,
      ...padding(4),
      borderRadius: 12,
      backgroundColor: iconBlockColor,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
    },
  };
};

export const InfoCardRules = createCachedStyle(InfoCardRuleFn);

import {FC} from 'react';
import {memo } from 'react';
import {useStyle} from '../../utils/theme/useStyle';
import {BannerWbOzonRules, IBannerWbOzonKeys} from './BannerWbOzon.style';
import {useTheme} from '../../utils/theme/useTheme';
import Text from 'src/components/UI/Text/Text';
import {Button} from '../UI/Button/Button';
import OzonLogo from 'src/assets/svg/ozon.svg'
import WBLogo from 'src/assets/svg/wb.svg'
import { ReactComponent as CrossIcon }  from 'src/assets/icons-clear/cross.svg'
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '../../routing/routeConfig';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface Props {
  closeBanner: () => void;
  extend?: Partial<IBannerWbOzonKeys>
}

export const BannerWbOzon: FC<Props> = memo(function BannerWbOzon(props) {
  const { closeBanner, extend } = props;
  const { css } = useStyle(BannerWbOzonRules, { extend });
  const { theme } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToProductsIntegrations = () => {
    navigate(RoutePath.IntegrationProducts)
  };

  return (
    <div className={css.root}>
      <button
        className={css.closeButton}
        onClick={closeBanner}
      >
        <CrossIcon fill={theme.colorWhite} width={16} height={16} />
      </button>
      <img
        src={OzonLogo}
        className={css.ozon}
      />
      <img
        src={WBLogo}
        className={css.wb}
      />
      <Text
        text={t('bannerWbOzon.integrationWithWbAndOzon')}
        mod='title'
        fontWeight={700}
        fontSize={20}
        lineHeight={22}
        extend={css.title}
      />
      <Text
        text={t('bannerWbOzon.weWillUploadAndUpdateProducts')}
        mod='text'
        fontSize={12}
        fontWeight={500}
        lineHeight={16}
        extend={css.text}
      />
      <Button
        propsStyles={{
          width: '100%',
          padding: 12,
          color: theme.colorBlack,
          background: 'rgba(255, 255, 255, 0.8)'
        }}
        text={t('bannerWbOzon.configure')}
        hasGradient={false}
        extend={css.button}
        onClick={goToProductsIntegrations}
      />
    </div>
  )
})

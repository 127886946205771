import {ChangeEvent, FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import Modal, {ModalNames} from '../Modal/Modal';
import {useStyle} from 'src/utils/theme/useStyle';
import {ModalSaveUploadedProductsRules} from './ModalSaveUploadedProducts.style';
import {Button} from '../UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import {useTranslation} from 'src/utils/i18n/hooks/useTranslation';
import {OptionCard} from '../OptionCard/OptionCard';
import {ImportType} from '@teleport/schemas-protobuf/port/v1/port_grpc_admin_pb';
import {useTheme} from '../../utils/theme/useTheme';
import {useNavigate} from 'react-router-dom';
import {resetUploadedProductsNetworkStatus, uploadedProductsState, uploadProducts} from '../../redux/slices/uploadedProductsSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {NetworkStatus} from '../../utils/network/network.constant';
import {RoutePath} from '../../routing/routeConfig';
import {resetNetworkStatus} from '../../redux/slices/productsSlice';
import {openErrorModal} from '../../redux/slices/modalErrorSlice';

interface IProps {
  active: boolean;
  onClose: () => void;
  fileUnitArray: Uint8Array
}

export const ModalSaveUploadedProducts: FC<IProps> = memo(function ModalSaveUploadedProducts(props) {
  const { active, onClose, fileUnitArray } = props;
  const { t } = useTranslation()
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(ModalSaveUploadedProductsRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const dispatch = useAppDispatch();
  const [importType, setImportType] = useState<ImportType>(ImportType.ALL_ENABLED);
  const { networkStatus: uploadedNetworkStatus } = useAppSelector(uploadedProductsState)
  const { theme } = useTheme();
  const navigate = useNavigate();


  useEffect(() => {
    if (uploadedNetworkStatus === NetworkStatus.Done) {
      dispatch(resetNetworkStatus());
      navigate(RoutePath.Products);
    }
    if (uploadedNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('modalSaveUploadedProducts.failedAddProducts'),
      }))
    }
  }, [navigate, dispatch, t, uploadedNetworkStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetUploadedProductsNetworkStatus())
    }
  }, [dispatch])

  const onClickConfirmBtn = useCallback(() => {
    dispatch(uploadProducts({
      importType: importType,
      fileBlob: fileUnitArray,
    }));
  }, [dispatch, fileUnitArray, importType]);

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose])

  const onChangeImportType = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setImportType(ImportType[value])
  }, []);


  return <Modal
    name={ModalNames.SaveUploadedProducts}
    active={active}
    propsStyles={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
    onClose={closeModal}
  >
    <div ref={modalHeaderRef} className={css.header}>
      <Text
        text={t('modalSaveUploadedProducts.whereWouldYouLikeToSaveTheUploadedProducts')}
        mod='title'
      />
    </div>
    <div className={css.modalInner} ref={modalInnerRef}>
      <div className={css.grid}>
        <OptionCard
          title={t('modalSaveUploadedProducts.addToActive')}
          text={t('modalSaveUploadedProducts.productsWillBeAvailableForCustomersImmediately')}
          name='uploadProductsVariant'
          value={ImportType[ImportType.ALL_ENABLED]}
          checked={importType === ImportType.ALL_ENABLED}
          type='radio'
          onChange={onChangeImportType}
          bg={theme.background}
        />
        <OptionCard
          title={t('modalSaveUploadedProducts.addToHidden')}
          text={t('modalSaveUploadedProducts.youWillHaveToUnhideProductsManuallyToMakeThemAvailableForCustomers')}
          name='uploadProductsVariant'
          value={ImportType[ImportType.ALL_DISABLED]}
          checked={importType === ImportType.ALL_DISABLED}
          type='radio'
          onChange={onChangeImportType}
          bg={theme.background}
        />
      </div>
      <div className={css.footer}>
        <Button
          text={t('modalSaveUploadedProducts.сonfirm')}
          hasGradient={false}
          type='submit'
          propsStyles={{
            width: '100%'
          }}
          onClick={onClickConfirmBtn}
          disabled={uploadedNetworkStatus === NetworkStatus.Loading}
        />
      </div>
    </div>
  </Modal>;

})

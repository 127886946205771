import {FC, memo, useCallback, useEffect, useState} from 'react';
import {useStyle} from '../../utils/theme/useStyle';
import useLoader from '../../hooks/useLoader';
import {useNavigate} from 'react-router-dom';
import useBackButton from '../../hooks/useBackButton';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import {Layout} from '../../components/Layout/Layout';
import Text from '../../components/UI/Text/Text';
import Footer from '../../components/Footer/Footer';
import {Button} from '../../components/UI/Button/Button';
import {ProductsIntegrationPageRules} from './ProdcutsIntegration.style';
import Container from '../../components/Container/Container';
import {IntegrationStatus} from '../../blocks/IntegrationStatus/IntegrationStatus';
import {InfoCard} from '../../components/InfoCard/InfoCard';
import {useTheme} from '../../utils/theme/useTheme';
import {
  ModalChooseIntegrationService
} from '../../components/ModalChooseIntegrationService/ModalChooseIntegrationService';
import {useModalState} from '../../hooks/useModalState';
import {ModalWb} from '../../components/ModalWb/ModalWb';
import {ModalOzon} from '../../components/ModalOzon/ModalOzon';
import {ModalBitrix} from '../../components/ModalBitrix/ModalModalBitrix';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  integrationProductsState,
  ProviderIntegrationCase,
  providerIntegrationGetState,
  resetNetworkStatus
} from '../../redux/slices/integrationProductsSlice';
import {NetworkStatus} from '../../utils/network/network.constant';
import {openErrorModal} from '../../redux/slices/modalErrorSlice';
import ModalSuccess from 'src/components/ModalSuccess/ModalSuccess';
import { findEnumValueByPartialMatch } from 'src/utils/findEnumValueByPartialMatch';
import { ProviderIntegrationError } from '@teleport/schemas-protobuf';
import { togglesState } from 'src/redux/slices/configSlice';

export const ProductsIntegrationPage: FC = memo(function ProductsIntegrationPage() {
  const { css, rules } = useStyle(ProductsIntegrationPageRules);
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getProviderNetworkStatus, upsertProviderIntegrationNetworkStatus, integrationUpserterrorText } = useAppSelector(integrationProductsState);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [renderWbModal, activeWbModal, openWbModal, closeWbModal] = useModalState();
  const [renderOzonModal, activeOzonModal, openOzonModal, closeOzonModal] = useModalState();
  const [renderBitrixModal, activeBitrixModal, openBitrixModal, closeBitrixModal] = useModalState();
  const [renderSuccessModal, activeSuccessModal, openSuccessModal, closeSuccessModal] = useModalState();
  const { showBackendErrors } = useAppSelector(togglesState);

  const [currentProviderName, setCurrentProviderName] = useState('')

  useEffect(() => {
    hideLoader()
  }, [hideLoader]);

  useEffect(() => {
    if (getProviderNetworkStatus === NetworkStatus.None) {
      dispatch(providerIntegrationGetState())
    }
    if (getProviderNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('productsIntegrationPage.failedGetIntegrationData')
      }))
    }
  }, [dispatch, t, getProviderNetworkStatus]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const onSubmitCallback = useCallback(provider => {
    if (provider === ProviderIntegrationCase.Wildberries) {
      closeWbModal();
      setCurrentProviderName('WB')
    }
    if (provider === ProviderIntegrationCase.Ozon) {
      closeOzonModal();
      setCurrentProviderName('Ozon')
    }
    if (provider === ProviderIntegrationCase.Bitrix) {
      closeBitrixModal();
      setCurrentProviderName(t('productsIntegrationPage.bitrix'))
    }
  }, [closeBitrixModal, closeOzonModal, closeWbModal, t])

  useEffect(() => {

    const customError = findEnumValueByPartialMatch(integrationUpserterrorText, ProviderIntegrationError );
    let modalErrorText: string;
    if (customError) {
      modalErrorText = t('productsIntegrationPage.checkTokenValidityOrContactUs')
    } else {
      modalErrorText = showBackendErrors? integrationUpserterrorText : t('layout.somethingWentWrong');
    } 
    if (upsertProviderIntegrationNetworkStatus === NetworkStatus.Done) {
      openSuccessModal();
      dispatch(resetNetworkStatus('upsertProviderIntegrationNetworkStatus'));
    }
    if (upsertProviderIntegrationNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('productsIntegrationPage.weHaventConnected', currentProviderName ?? ''),
        errorText: modalErrorText
      }));
      dispatch(resetNetworkStatus('upsertProviderIntegrationNetworkStatus'));
    }

  }, [upsertProviderIntegrationNetworkStatus, currentProviderName, dispatch, openSuccessModal, t, integrationUpserterrorText, showBackendErrors])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('productsIntegrationPage.syncingProducts')}
            mod='title'
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            hasGradient={false}
            text={t('productsIntegrationPage.configure')}
            propsStyles={{ width: '100%' }}
            onClick={openModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        <div className={css.contentWrapper}>
          <IntegrationStatus
            extend={{
              root: rules.integrationStatus
            }}
            openIntegrationModal={openModal}
          />
          <div className={css.infoWrapper}>
            <InfoCard
              icon='trash'
              title={t('productsIntegrationPage.allItemsWillBeDeleted')}
              text={t('productsIntegrationPage.allPreviouslyUploadedItemsWillBeRemovedFromYourApplication')}
              iconBlockColor={theme.colorPink}
            />
            <InfoCard
              icon='toggles'
              title={t('productsIntegrationPage.selectOneService')}
              text={t('productsIntegrationPage.youCanConnectOnlyOneServiceAtATimeForProductSynchronization')}
              iconBlockColor={theme.colorBlue}
            />
            <InfoCard
              icon='cloud'
              title={t('productsIntegrationPage.chooseOneService')}
              text={t('productsIntegrationPage.onlyOneServiceCanBeConnectedAtATimeForProductSynchronization')}
              iconBlockColor={theme.colorPurple}
            />
          </div>
        </div>
      </Container>
      {renderModal
        && <ModalChooseIntegrationService
          active={activeModal}
          onClose={closeModal}
          openWbModal={openWbModal}
          openOzonModal={openOzonModal}
          openBitrixModal={openBitrixModal}
        />}
      {renderWbModal && <ModalWb onSubmitCallback={onSubmitCallback} active={activeWbModal} onClose={closeWbModal} />}
      {renderOzonModal && <ModalOzon onSubmitCallback={onSubmitCallback} active={activeOzonModal} onClose={closeOzonModal}  /> }
      {renderBitrixModal && <ModalBitrix onSubmitCallback={onSubmitCallback} active={activeBitrixModal} onClose={closeBitrixModal} />}
      {renderSuccessModal && <ModalSuccess active={activeSuccessModal} onClose={closeSuccessModal} title={t('productsIntegrationPage.hasBeenConnectedAndWeAreUploadingProducts', currentProviderName)} text={t('productsIntegrationPage.usuallyTheProcessTakes24HoursWeWillWriteToYouOnceEverythingIsReadyInTheMeantimeYouCanCloseTheApp')} buttonCoor={'colorBlueDark'} buttonText={t('productsIntegrationPage.understood')} />}
    </Layout>
  );
});

import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface INoSettingsKeys {
  wrapper: {};
  icon: {};
  title: {};
  text: {};
}

interface INoSettingsProps {
  headerHeight: number | undefined;
  footerHeight: number | undefined;
}

export type INoSettings = IRuleFn<INoSettingsKeys, INoSettingsProps>;

const NoSettingsRuleFn: INoSettings = props => {
  const { mainColor, colorDarkGrey } = props.theme;

  return {
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    icon: {
      width: 100,
      height: 100,
      marginBottom: 12,
      fill: mainColor,
    },
    title: {
      marginBottom: 8,
    },
    text: {
      color: colorDarkGrey,
      maxWidth: 295
    },
  };
};

export const NoSettingsRules = createCachedStyle(NoSettingsRuleFn);

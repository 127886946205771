import { FC, memo, useEffect, useState } from 'react';
import useLoader from 'src/hooks/useLoader';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Footer from 'src/components/Footer/Footer';
import Container from 'src/components/Container/Container';
import { useStyle } from 'src/utils/theme/useStyle';
import { SubscriptionPageRules } from './SubscriptionPage.style';
import { useTheme } from 'src/utils/theme/useTheme';
import Text from 'src/components/UI/Text/Text';
import { useModalState } from 'src/hooks/useModalState';
import ModalPromocode from 'src/components/ModalPromocode/ModalPromocode';
import ModalSuccess from 'src/components/ModalSuccess/ModalSuccess';
import ModalRates from 'src/components/ModalRates/ModalRates';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from 'src/utils/telegramProvider';

const successSubscrDemoData = [
  {
    id: 1,
    txt1: 'Тариф',
    txt2: 'Ежегодный',
  },
  {
    id: 2,
    txt1: 'Платеж',
    txt2: '21 600 ₽',
  },
  {
    id: 3,
    txt1: 'Статус подписки',
    txt2: 'Активна',
  },
  {
    id: 4,
    txt1: 'Следующий платеж',
    txt2: '14.02.2024',
  },
];

export const SubscriptionPage: FC = memo(function SubscriptionPage() {
  const { css } = useStyle(SubscriptionPageRules);
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const Telegram = useTelegram();

  useEffect(() => {
    function goBack() {
      navigate(-1);
    }

    BackButton.onClickCustom(goBack);

    return () => {
      BackButton.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const [applyedPromocode, setApplyedPromocode] = useState('');
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [renderModal2, activeModal2, openModal2, closeModal2] = useModalState();
  const [renderModal3, activeModal3, openModal3, closeModal3] = useModalState();
  const [renderModalSuccess, activeModalSuccess, openModalSuccess, closeModalSuccess] =
    useModalState();

  const applyPromocode = (promocode: string) => {
    setApplyedPromocode(promocode);
    openModal2();
  };

  const openPromocodeModal = () => {
    openModal();
  };

  const openRatesModal = () => {
    openModal3();
  };

  const onRateSelect = () => {
    openModalSuccess();
  };

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };


  return (
    <Layout
      footer={
        <Footer>
          <Button
            text="Связаться с поддержкой"
            propsStyles={{ width: '100%', background: theme.colorBlack }}
            hasGradient={false}
            onClick={connectToSupport}
          />
        </Footer>
      }
    >
      <Container>
        <>
          <Text
            mod={'title'}
            text={'Подписка'}
            extend={css.title}
            fontSize={20}
            lineHeight={22}
          />
          <div className={css.grid}>
            <div>
              <Text
                mod={'text'}
                text={'Текущий тариф'}
                extend={css.txtBlockTitle}
                fontSize={12}
                lineHeight={14}
              />
              <Text
                mod={'text'}
                text={'Ежемесячный'}
                extend={css.txtBlockTitle}
                fontSize={16}
                lineHeight={18}
                fontWeight={800}
              />
            </div>
            <div>
              <Text
                mod={'text'}
                text={'Платеж'}
                extend={css.txtBlockTitle}
                fontSize={12}
                lineHeight={14}
              />
              <Text
                mod={'text'}
                text={'2 000 ₽'}
                extend={css.txtBlockTitle}
                fontSize={16}
                lineHeight={18}
                fontWeight={800}
              />
            </div>
            <div>
              <Text
                mod={'text'}
                text={'Статус подписки'}
                extend={css.txtBlockTitle}
                fontSize={12}
                lineHeight={14}
              />
              <Text
                mod={'text'}
                text={'Активна'}
                extend={css.txtBlockTitle}
                fontSize={16}
                lineHeight={18}
                fontWeight={800}
              />
            </div>
            <div>
              <Text
                mod={'text'}
                text={'Следующий платеж'}
                extend={css.txtBlockTitle}
                fontSize={12}
                lineHeight={14}
              />
              <Text
                mod={'text'}
                text={'14.03.2023'}
                extend={css.txtBlockTitle}
                fontSize={16}
                lineHeight={18}
                fontWeight={800}
              />
            </div>
          </div>
          <div className={css.profileActions}>
            <button onClick={openPromocodeModal} className={css.btn}>
              Ввести промокод
            </button>
            <button onClick={openRatesModal} className={css.btn}>
              Сменить тариф
            </button>
            <button className={`${css.btn} ${css.cancelColor}`}>Отменить подписку</button>
          </div>
        </>
      </Container>
      {renderModal && (
        <ModalPromocode
          active={activeModal}
          onClose={closeModal}
          applyPromocode={applyPromocode}
          value={applyedPromocode}
        />
      )}
      {renderModal2 && (
        <ModalSuccess title={'Подарили 30 дней'} active={activeModal2} onClose={closeModal2} />
      )}
      {renderModal3 && (
        <ModalRates onRateSelect={onRateSelect} active={activeModal3} onClose={closeModal3} />
      )}
      {renderModalSuccess && (
        <ModalSuccess
          title={'Подписка оплачена'}
          active={activeModalSuccess}
          onClose={closeModalSuccess}
        >
          <div className={css.success_rows}>
            {successSubscrDemoData.map(el => {
              return (
                <div key={el.id} className={css.success_row}>
                  <Text
                    mod="text"
                    text={el.txt1}
                    fontSize={12}
                    fontWeight={800}
                    lineHeight={18}
                    textTransform={'upppercase'}
                  />
                  <Text
                    mod="text"
                    text={el.txt2}
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={18}
                  />
                </div>
              );
            })}
          </div>
        </ModalSuccess>
      )}
    </Layout>
  );
});

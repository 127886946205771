import {NetworkStatus} from '../../utils/network/network.constant';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {createAppAsyncThunk} from '../../hooks/redux';
import {DeliveryMethodTranslator} from '../translators/deliveryMethodsTranslator';

export enum DeliveryCases {
  customMethod = 'customMethod',
}

interface IDeliveryMethodCustom {
  uuid: string;
  name: string;
  description: string;
}

export type IDeliveryMethod = {
  value: IDeliveryMethodCustom;
  case: DeliveryCases;
};

interface IDeliveryMethodSlice {
  deliveryMethods: IDeliveryMethod[];
  getListNetworkStatus: NetworkStatus,
  createNetworkStatus: NetworkStatus,
  upsertNetworkStatus: NetworkStatus,
  deleteNetworkStatus: NetworkStatus,
  errorText?: string;
}

const initialState: IDeliveryMethodSlice = {
  deliveryMethods: [],
  getListNetworkStatus: NetworkStatus.None,
  createNetworkStatus: NetworkStatus.None,
  upsertNetworkStatus: NetworkStatus.None,
  deleteNetworkStatus: NetworkStatus.None,
}

export const deliveryMethodList = createAppAsyncThunk(
  'DeliveryMethod/deliveryMethodList',
  async (_, thunkAPI) => {
    const response = await thunkAPI.extra.portAdminApi().deliveryMethodList({});
    return DeliveryMethodTranslator.fromDeliveryMethodListResponse(response)
  },
);

export const createDeliveryMethod = createAppAsyncThunk(
  'DeliveryMethod/createDeliveryMethod',
  async (deliveryMethod: IDeliveryMethod, thunkAPI) => {
    const requestData = DeliveryMethodTranslator.toDeliveryMethodUpsertRequest(deliveryMethod);
    const result = await thunkAPI.extra.portAdminApi().deliveryMethodUpsert(requestData);
    const deliveryMethodData: IDeliveryMethod = {
      value: {
        ...deliveryMethod.value, 
        uuid: result.deliveryMethodUuid,
      },
      case: deliveryMethod.case
    };
    return deliveryMethodData;
  },
);

export const upsertDeliveryMethod = createAppAsyncThunk(
  'DeliveryMethod/upsertDeliveryMethod',
  async (deliveryMethod: IDeliveryMethod, thunkAPI) => {
    const requestData = DeliveryMethodTranslator.toDeliveryMethodUpsertRequest(deliveryMethod)
    await thunkAPI.extra.portAdminApi().deliveryMethodUpsert(requestData)
    const deliveryMethodData: IDeliveryMethod = {
      value: {
        ...deliveryMethod.value,
      },
      case: deliveryMethod.case
    }
    return deliveryMethodData
  },
);

export const deliveryMethodDelete = createAppAsyncThunk(
  'DeliveryMethod/deleteDeliveryMethod',
  async (deliveryMethodUuid: string, thunkAPI) => {
    await thunkAPI.extra.portAdminApi().deliveryMethodDelete({
      deliveryMethodUuid: deliveryMethodUuid
    })
    return deliveryMethodUuid
  },
);

export const deliveryMethodSlice = createSlice({
  name: 'DeliveryMethod',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'getListNetworkStatus' | 'createNetworkStatus' | 'upsertNetworkStatus' | 'deleteNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(createDeliveryMethod.pending, state => {
      state.errorText = '';
      state.createNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createDeliveryMethod.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.createNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(createDeliveryMethod.fulfilled, (state, action) => {
      state.createNetworkStatus = NetworkStatus.Done;
      state.deliveryMethods.push(action.payload)
    });

    builder.addCase(upsertDeliveryMethod.pending, state => {
      state.errorText = '';
      state.upsertNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(upsertDeliveryMethod.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(upsertDeliveryMethod.fulfilled, (state, action) => {
      const deliveryMethod = action.payload;
      const element = state.deliveryMethods.find(el => el.value.uuid === deliveryMethod.value.uuid);
      const index = state.deliveryMethods.indexOf(element);
      if (index !== -1) {
        state.deliveryMethods.splice(index, 1, deliveryMethod);
      }
      state.upsertNetworkStatus = NetworkStatus.Done;
    });

    builder.addCase(deliveryMethodDelete.pending, state => {
      state.errorText = '';
      state.deleteNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(deliveryMethodDelete.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.deleteNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(deliveryMethodDelete.fulfilled, (state, action) => {
      state.deliveryMethods = state.deliveryMethods.filter(method => method.value.uuid !== action.payload)
      state.deleteNetworkStatus = NetworkStatus.Done;
    });

    builder.addCase(deliveryMethodList.pending, state => {
      state.errorText = '';
      state.getListNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(deliveryMethodList.rejected, (state, action) => {
      state.errorText = action.error.message;
      state.getListNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(deliveryMethodList.fulfilled, (state, action) => {
      state.getListNetworkStatus = NetworkStatus.Done;
      state.deliveryMethods = action.payload;
    });
  },
});

export const { resetNetworkStatus } = deliveryMethodSlice.actions;

export const deliveryMethodSelector = (state: RootState) => state[deliveryMethodSlice.name];

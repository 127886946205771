import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { Layout } from 'src/components/Layout/Layout';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { PaymentPageRules } from './PaymentPage.style';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { useTheme } from 'src/utils/theme/useTheme';
import { INoSettingsKeys } from 'src/components/NoSettings/NoSettings.style';
import { DefaultCard } from 'src/components/DefaultCard/DefaultCard';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { paymentMethodList, paymentMethodSelector, resetNetworkStatus } from 'src/redux/slices/paymentMethodsSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import useLoader from 'src/hooks/useLoader';
import { openErrorModal } from 'src/redux/slices/modalErrorSlice';
import { togglesState } from 'src/redux/slices/configSlice';
import {useModalState} from '../../hooks/useModalState';
import {ModalAddPaymentMethod} from '../../components/ModalAddPaymentMethod/ModalAddPaymentMethod';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  extend?: Partial<INoSettingsKeys>
}

export const PaymentPage: FC<IProps> = memo(function PaymentPage(props) {
  const { extend } = props;
  const { css } = useStyle(PaymentPageRules, { extend });
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { paymentMethods, getListNetworkStatus, errorText } = useAppSelector(paymentMethodSelector)
  const dispatch = useAppDispatch();
  const [selectedMethodUuid, setSelectedMethodUuid] = useState('');
  const [renderPaymentModal, activePaymentModal, openPaymentModal, closePaymentModal] = useModalState();
  const { t } = useTranslation()
  const { showBackendErrors } = useAppSelector(togglesState);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const Telegram = useTelegram();

  useEffect(() => {
    if (getListNetworkStatus === NetworkStatus.None) {
      dispatch(paymentMethodList())
    }
    if (getListNetworkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (getListNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
    if (getListNetworkStatus === NetworkStatus.Failed) {
      hideLoader();
      dispatch(openErrorModal({
        errorTitle: t('paymentPage.couldntReceivePaymentMethods'),
        errorText: showBackendErrors ? errorText : ''
      }));
    }
  }, [getListNetworkStatus, dispatch, hideLoader, showLoader, t, errorText, showBackendErrors]);

  const onClickPaymentCard = uuid => {
    setSelectedMethodUuid(uuid);
    openPaymentModal();
  }

  const onClickAddPaymentMethod = () => {
    setSelectedMethodUuid('');
    openPaymentModal();
  }

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };


  useEffect(() => {
    return () => {
      dispatch(resetNetworkStatus('getListNetworkStatus'))
    }
  }, [dispatch])

  return (
    <Layout
      mainFlexColumn={true}
      padding={'6px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('paymentPage.payment')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('paymentPage.addPaymentMethod')}
            propsStyles={{ width: '100%' }}
            onClick={onClickAddPaymentMethod}
          />
        </Footer>
      }
    >
      {paymentMethods.length > 0 ? (
        <Container extend={css.container}>
          <div className={css.textBlocksWrapper}>
            {paymentMethods.map(el => (
              <DefaultCard 
                key={el.value.uuid}
                uuid={el.value.uuid}
                title={el.value.name}
                text={el.value.description}
                onClick={onClickPaymentCard}
              />
            ))}
          </div>
        </Container>
      ) : (
        <NoSettings
          title={t('paymentPage.youHaventAddedPaymentMethodsYet')}
          text={t('paymentPage.addPaymentMethodsSoUsersCanPayForOrdersOnTheirOwn')}
          icon="payment-large"
        />
      )}
      <Container>
        <div className={css.btnWrp}>
          <Button
            text={t('paymentPage.contactSupport')}
            propsStyles={{ width: '100%', background: theme.colorBlack }}
            onClick={connectToSupport}
          />
        </div>
      </Container>
      {renderPaymentModal && <ModalAddPaymentMethod active={activePaymentModal} onClose={closePaymentModal} paymentMethodUuid={selectedMethodUuid} />}
    </Layout>
  );
});

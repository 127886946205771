import {FC, memo, useEffect, useMemo} from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { integrationsPageRules } from './IntegrationsPage.style';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import Container from 'src/components/Container/Container';
import { useTheme } from 'src/utils/theme/useTheme';
import { RoutePath } from 'src/routing/routeConfig';
import { IntegrationType } from '../../redux/slices/integrationTelegramSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {togglesState} from '../../redux/slices/configSlice';
import {integrationProductsState, providerIntegrationGetState} from '../../redux/slices/integrationProductsSlice';
import {Provider, ProviderSyncState} from '@teleport/schemas-protobuf';
import {NetworkStatus} from '../../utils/network/network.constant';

export const IntegrationsPage: FC = memo(function IntegrationsPage() {
  const { css } = useStyle(integrationsPageRules);
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const Telegram = useTelegram();
  const {ozonIntegrationEnabled, wbIntegrationEnabled, bitrixIntegrationEnabled} = useAppSelector(togglesState)
  const { syncState, provider, getProviderNetworkStatus } = useAppSelector(integrationProductsState);
  const dispatch = useAppDispatch();
  const { theme } = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (getProviderNetworkStatus === NetworkStatus.None) {
      dispatch(providerIntegrationGetState());
    }
  }, [
    dispatch,
    syncState,
    getProviderNetworkStatus,
  ]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const selectCurrentIntegration = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetId = target.closest('.list-button')?.id;
    if (!targetId) return;
    switch (targetId) {
      case IntegrationType.telegram:
        navigate(RoutePath.IntegrationTelegram)
        return;
      case IntegrationType.products:
        navigate(RoutePath.IntegrationProducts)
        return;
      default:
        return;
    }
  };

  const providerName = useMemo(() => {
    if (provider === Provider.OZON) {
      return t('productsIntegrationPage.ozon')
    }
    if (provider === Provider.WILDBERRIES) {
      return t('productsIntegrationPage.wb');
    }
    if (provider === Provider.BITRIX) {
      return t('productsIntegrationPage.bitrix')
    }
    return undefined;
  }, [provider, t]);

  const status = useMemo(() => {
    if (syncState === ProviderSyncState.ACTIVE) {
      return t('productsIntegrationPage.active');
    }
    if (syncState === ProviderSyncState.PAUSED) {
      return t('productsIntegrationPage.paused');
    }
  }, [t, syncState]);

  const text = useMemo(() => {
    if (syncState === ProviderSyncState.UNSPECIFIED) {
      let text = t('integrationsPage.with');

      if (wbIntegrationEnabled) {
        text = text + ` ${t('integrationsPage.wb')}`
      }
      if (ozonIntegrationEnabled && wbIntegrationEnabled) {
        text = text + `, ${t('integrationsPage.ozon')}`
      } else if (ozonIntegrationEnabled) {
        text = text + ` ${t('integrationsPage.ozon')}`
      }
      if (ozonIntegrationEnabled && bitrixIntegrationEnabled) {
        text = text + ` ${t('integrationsPage.or')} ${t('integrationsPage.bitrix')}`
      } else if (wbIntegrationEnabled && bitrixIntegrationEnabled) {
        text = text + ` ${t('integrationsPage.or')} ${t('integrationsPage.bitrix')}`
      } else if (bitrixIntegrationEnabled) {
        text = text + ` ${t('integrationsPage.bitrix')}`
      }
      return text
    }
    return `${t('productsIntegrationPage.with')} ${providerName} ${status}`
  }, [
    t,
    syncState,
    providerName,
    status,
    wbIntegrationEnabled,
    ozonIntegrationEnabled,
    bitrixIntegrationEnabled
  ])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('integrationsPage.integrations')}
            mod='title'
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button hasGradient={false} text={t('integrationsPage.contactSupport')} onClick={connectToSupport} propsStyles={{ background: theme.colorBlack, width: '100%' }} />
        </Footer>
      }
    >
      <Container>
        <div className={css.grid}>
          <ListButton
            id={IntegrationType.telegram}
            title={t('integrationsPage.telegram')}
            text={t('integrationsPage.channelIntegrationBotConnection')}
            onClick={selectCurrentIntegration}
            titleFontSize={14}
            titleMarginBottom={4}
            padding='16px'
          />
          {
            (ozonIntegrationEnabled
            || wbIntegrationEnabled
            || bitrixIntegrationEnabled)
            && <ListButton
              id={IntegrationType.products}
              title={t('integrationsPage.syncingProducts')}
              text={text}
              onClick={selectCurrentIntegration}
              titleFontSize={14}
              titleMarginBottom={4}
              padding='16px'
            />
          }
        </div>
      </Container>
    </Layout>
  );
});

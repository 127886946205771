import { ChangeEvent, FC, memo, useEffect, useMemo, useState} from 'react';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { orderingAndDeliveryPageRules } from './OrderingAndDeliveryPage.style';
import useLoader from 'src/hooks/useLoader';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { Textarea } from 'src/components/UI/Textarea/Textarea';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  wizardStateSelector,
  resetNetworkStatus,
  setMinimalPrice,
  setPurchaseConfirmationMessage,
  setDeliveryType as setWizardDeliveryType,
  setHasBasket,
  Basket,
  setCheckoutNotesPlaceholder
} from 'src/redux/slices/wizardSlice';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { NetworkStatus } from 'src/utils/network/network.constant';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { Input } from 'src/components/UI/Input/Input';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { setDeliveryType } from 'src/redux/slices/configSlice';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ModalWarningAsyncChanges } from 'src/components/ModalWarningAsyncChanges/ModalWarningAsyncChanges';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import useCurrencySymbol from 'src/hooks/useCurrencySymbol';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';


interface IForm {
  hasBasket: Basket,
  deliveryType: DeliveryType,
  purchaseConfirmationMessage: string,
  checkoutNotesPlaceholder: string,
  minimalPrice: number,
  minimalPriceEnabled: boolean
}


export const OrderingAndDeliveryPage: FC = memo(function OrderingAndDeliveryPage() {
  const { css } = useStyle(orderingAndDeliveryPageRules);
  const { hideLoader } = useLoader();
  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
  useModalState();
  const dispatch = useAppDispatch()
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState('')
  const {
    wizard: wizardData,
    networkStatus,
    saveNetworkStatus,
  } = useAppSelector(wizardStateSelector);
  const BackButton = useBackButton();
  const navigate = useNavigate();

  const [noShowModalWarningAsyncChanges, setNoShowModalWarningAsyncChanges] = useLocalStorage('noShowModalWarningAsyncChanges');
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        if (!noShowModalWarningAsyncChanges) {
          openModal();
        }
      }, 1500);
      dispatch(resetNetworkStatus('saveNetworkStatus'));
      setTimeout(() => setSavedSuccessfully(false), 1500);
    }
  }, [saveNetworkStatus, noShowModalWarningAsyncChanges, dispatch, openModal]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, dispatch]);

  const onClickListButton = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;

    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;


    switch (targetId) {
      case 'deliveryType':
        setModalTitle(t('orderingAndDeliveryPage.showcase'))
        setModalType('deliveryType')
        openModalUniversal()
        return;
      case 'cart':
        setModalTitle(t('orderingAndDeliveryPage.cart'))
        setModalType('cartActivate')
        openModalUniversal()
        return;
      default:
        setModalTitle('default')
        return
    }
  }


  const onChangeMinimalPrice = event => {
    dispatch(setMinimalPrice(Number(event.target.value)))
  }

  const onChangeTextArea = event => {
    dispatch(setPurchaseConfirmationMessage(event.target.value))
  }
  const onChangeCheckoutNotesPlaceholder = event => {
    dispatch(setCheckoutNotesPlaceholder(event.target.value))
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    control
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      hasBasket: wizardData.hasBasket,
      deliveryType: wizardData.deliveryType,
      purchaseConfirmationMessage: wizardData.purchaseConfirmationMessage,
      checkoutNotesPlaceholder: wizardData.checkoutNotesPlaceholder,
      minimalPrice: wizardData.minimalPrice,
      minimalPriceEnabled: wizardData.minimalPriceEnabled
    },
  });

  const deliveryTypeValue = useWatch({
    control,
    name: 'deliveryType',
  });
  const minimalPriceEnabledValue = useWatch({
    control,
    name: 'minimalPriceEnabled',
  });
  const hasBasketValue =  useWatch({
    control,
    name: 'hasBasket',
  });

  const deliveryTypeTitle = useMemo(() => {
    switch (deliveryTypeValue) {
      case DeliveryType.SHOWCASE:
        return t('orderingAndDeliveryPage.yes')
      default:
        return t('orderingAndDeliveryPage.no')
    }
  }, [deliveryTypeValue, t])

  const setMinimalAmount = () => {
    setValue('minimalPriceEnabled', !minimalPriceEnabledValue);
    if (minimalPriceEnabledValue) {
      setValue('minimalPrice', 0);
    }
  }

  const onSubmit: SubmitHandler<IForm> = data => {
    const { hasBasket, deliveryType, purchaseConfirmationMessage,checkoutNotesPlaceholder, minimalPrice, minimalPriceEnabled } = data;
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizardData, hasBasket, deliveryType, purchaseConfirmationMessage, checkoutNotesPlaceholder, minimalPrice, minimalPriceEnabled },
      true,
    );
    dispatch(wizardSaveState({ requestData: wizardStateRequest })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(setWizardDeliveryType({deliveryType: data.deliveryType}))
        dispatch(setHasBasket({hasBasket: data.hasBasket, noResetDeliveryType: true}))
        dispatch(setDeliveryType(wizardData.deliveryType))
      }else {
        dispatch(openErrorModal({}))
      }
    });
  };

  const changeHasBasket = event => {
    const value: Basket = event.target.value;
    setValue('hasBasket', value);
    if (event.target.value === Basket.NoBasket) {
      setValue('minimalPriceEnabled', false);
      setValue('minimalPrice', 0);
    }
    closeModalUniversal()
  };

  const onChangeMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setValue('deliveryType', value);
    closeModalUniversal()
  };

  const disableShowCaseType = hasBasketValue === Basket.HasBasket
  const disabledBasket = deliveryTypeValue === DeliveryType.SHOWCASE;

  const submitForm = async () => {
    const result = await trigger();
    if (result) {
      handleSubmit(onSubmit)();
    }
  };

  const closeModalUniversalFuntion = () => {
    closeModalUniversal();
  }

  const currentCurrencySymbol = useCurrencySymbol(wizardData?.currency);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('orderingAndDeliveryPage.checkout')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
            textTransform="uppercase"
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            successfully={savedSuccessfully && networkStatus !== NetworkStatus.Loading}
            text={t('orderingAndDeliveryPage.save')}
            onClick={submitForm}
            propsStyles={{ width: '100%' }}
            disabled={saveNetworkStatus === NetworkStatus.Loading || savedSuccessfully}
          />
        </Footer>
      }
    >
      <Container>
        <form >
          <ListButton
            id={'deliveryType'}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={t('orderingAndDeliveryPage.showcase')}
            placeholder={deliveryTypeTitle}
            onClick={onClickListButton}
          />
          <ListButton
            id={'cart'}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={t('orderingAndDeliveryPage.cart')}
            placeholder={hasBasketValue === Basket.HasBasket ? t('orderingAndDeliveryPage.yes') : t('orderingAndDeliveryPage.no')}
            onClick={onClickListButton}
          />
          {
            hasBasketValue === Basket.HasBasket && (
              <div className={css.wrapperJustify}>
                <Text text={t('orderingAndDeliveryPage.minimumOrderAmount')} mod="title" fontSize={12} />
                <Toggler
                  name={'disable-store'}
                  checked={minimalPriceEnabledValue}
                  value="show-in-catalog"
                  onChange={setMinimalAmount}
                />
              </div>
            )
          }

          {
            hasBasketValue === Basket.HasBasket && minimalPriceEnabledValue && (
              <Input
                name={'minimalPrice'}
                {...register('minimalPrice', {
                  validate: value =>
                    !minimalPriceEnabledValue || hasBasketValue !== Basket.HasBasket || value > 0 || t('orderingAndDeliveryPage.requiredField')
                })}
                onChange={onChangeMinimalPrice}
                controlled={false}
                type="number"
                placeholder={t('orderingAndDeliveryPage.enterMinAmount')}
                maxLength={70}
                symbol={currentCurrencySymbol}
                errorMessage={errors?.minimalPrice?.message}
              />
            )
          }
          {
            deliveryTypeValue !== DeliveryType.SHOWCASE && (
              <>
                <div className={css.textAreaWrp}>
                  <Textarea
                    name={'checkoutNotesPlaceholder'}
                    {...register('checkoutNotesPlaceholder')}
                    controlled={false}
                    label={t('orderingAndDeliveryPage.whatTheClientNeedsToProvideWhenPlacingAnOrder')}
                    placeholder={t('orderingAndDeliveryPage.whatBesidesTheirNameAndPhoneNumberShouldTheClientProvideForYouToDeliverTheirOrder')}
                    onChange={onChangeCheckoutNotesPlaceholder}
                  />
                </div>
                <div className={css.textAreaWrp}>
                  <Textarea
                    name={'purchaseConfirmationMessage'}
                    {...register('purchaseConfirmationMessage')}
                    controlled={false}
                    label={t('orderingAndDeliveryPage.messageTextForTheClientAfterPlacingAnOrder')}
                    placeholder={t('orderingAndDeliveryPage.willBeSentToTheClientTelegramChatAfterPlacingAnOrder')}
                    onChange={onChangeTextArea}
                  />
                </div>
              </>
            )
          }
        </form>
      </Container>
      {renderModalUniversal && (
        <ModalUniversal
          title={modalTitle}
          active={activeModalUniversal}
          onClose={closeModalUniversalFuntion}
        >
          <>
            <div className={css.grid}>
              {
                modalType === 'deliveryType' && (
                  <>
                    <OptionCard
                      name="order-method"
                      title={t('orderingAndDeliveryPage.yes')}
                      text={t('orderingAndDeliveryPage.userWillBeAbleToBrowseProductsInTheAppButWillNotBeAbleToPlaceAnOrder')}
                      type="radio"
                      value={`${DeliveryType.SHOWCASE}`}
                      disabled={disableShowCaseType}
                      disabledMessage={disableShowCaseType ? t('orderingAndDeliveryPage.theShowcaseIsNotCompatibleWithTheCart') : ''}
                      checked={disableShowCaseType? false: deliveryTypeValue === DeliveryType.SHOWCASE}
                      onChange={onChangeMethod}
                      bg={'#F0F0F0'}
                    />
                    <OptionCard
                      name="order-method"
                      title={t('orderingAndDeliveryPage.no')}
                      text={t('orderingAndDeliveryPage.userWillBeAbleToOrderProductsInTheApp')}
                      type="radio"
                      value={`${DeliveryType.REQUEST}`}
                      checked={deliveryTypeValue === DeliveryType.REQUEST}
                      onChange={onChangeMethod}
                      bg={'#F0F0F0'}
                    />
                  </>
                )
              }

              {
                modalType === 'cartActivate' && (
                  <>
                    <OptionCard
                      name="hasBasket"
                      title={t('orderingAndDeliveryPage.yes')}
                      text={t('orderingAndDeliveryPage.theUserWillBeAblToOrderMultipleItemsAndProceedToCheckoutfromTheCart')}
                      type="radio"
                      value={Basket.HasBasket}
                      disabled={disabledBasket}
                      disabledMessage={disabledBasket ? t('orderingAndDeliveryPage.cartIsNotCompatibleWithShowcase') : ''}
                      checked={disabledBasket ? false : hasBasketValue === Basket.HasBasket}
                      onChange={changeHasBasket}
                      bg={'#F0F0F0'}
                    />
                    <OptionCard
                      name="hasBasket"
                      title={t('orderingAndDeliveryPage.no')}
                      text={t('orderingAndDeliveryPage.theUserWillBeAbleToOrderOnlyOneItemAndProceedToCheckoutFromTheProductPage')}
                      type="radio"
                      value={Basket.NoBasket}
                      checked={disabledBasket ? true : hasBasketValue === Basket.NoBasket}
                      onChange={changeHasBasket}
                      bg={'#F0F0F0'}
                    />
                  </>
                )
              }

            </div>
          </>
        </ModalUniversal>
      )}
      {renderModal && <ModalWarningAsyncChanges onClickNoShowMore={setNoShowModalWarningAsyncChanges} onClose={closeModal} active={activeModal} />}
    </Layout>
  );
});

import {IRuleFn} from '../../utils/theme/fela.interface';
import {padding} from '../../utils/applyCSSProperty ';
import {createCachedStyle} from '../../utils/theme/createCachedStyle';

export interface IModalAddDeliveryMethodKeys {
  modalInner: {};
  header: {};
  block: {};
  commentBlock: {};
  footer: {};
}

interface IModalAddDeliveryMethodProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalAddDeliveryMethod = IRuleFn<IModalAddDeliveryMethodKeys, IModalAddDeliveryMethodProps>;

const ModalAddDeliveryMethodRuleFn: IModalAddDeliveryMethod = props => {
  const { sidePadding, colorWhite } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      ...padding(12, sidePadding, `${footerHeight}px`),
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(20, sidePadding, 12),
    },
    block: {
      paddingBottom: 12,
    },
    commentBlock: {
      ...padding(12, 0, 30),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
  };
};

export const ModalAddDeliveryMethodRules = createCachedStyle(ModalAddDeliveryMethodRuleFn);

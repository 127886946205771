import {NetworkStatus} from '../../utils/network/network.constant';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {Provider, ProviderSyncState} from '@teleport/schemas-protobuf';
import {createAppAsyncThunk} from '../../hooks/redux';
import {IntegrationProductsTranslator} from '../translators/integrationProductsTranslator';

export enum ProviderIntegrationCase {
  Wildberries = 'wildberries',
  Ozon = 'ozon',
  Bitrix = 'bitrix',
}

export interface IProviderIntegrationWildberries {
  token: string;
}

export interface IProviderIntegrationOzon {
  clientId: string;
  apiKey: string;
}

export interface IProviderIntegrationBitrix {
  fileUrl: string;
}

export interface IProviderIntegration {
  provider: {
    value: IProviderIntegrationWildberries;
    case: ProviderIntegrationCase.Wildberries;
  } | {
      value: IProviderIntegrationOzon;
      case: ProviderIntegrationCase.Ozon;
  } | {
    value: IProviderIntegrationBitrix;
    case: ProviderIntegrationCase.Bitrix;
  }
}

interface IIntegrationSliceState {
  provider: Provider;
  syncState: ProviderSyncState;
  lastSyncedAt?: number;
  getProviderNetworkStatus: NetworkStatus;
  changeSyncStateNetworkStatus: NetworkStatus;
  upsertProviderIntegrationNetworkStatus: NetworkStatus;
  integrationUpserterrorText: string;
}

const initialState: IIntegrationSliceState = {
  provider: Provider.UNSPECIFIED,
  syncState: ProviderSyncState.UNSPECIFIED,
  lastSyncedAt: undefined,
  getProviderNetworkStatus: NetworkStatus.None,
  changeSyncStateNetworkStatus: NetworkStatus.None,
  upsertProviderIntegrationNetworkStatus: NetworkStatus.None,
  integrationUpserterrorText: ''
};

export const providerIntegrationGetState = createAppAsyncThunk(
  'IntegrationProducts/providerIntegrationGetState',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portAdminApi().providerIntegrationGetState({})
    return IntegrationProductsTranslator.fromProviderIntegrationGetStateResponse(result)
  },
);

export const providerIntegrationUpsert = createAppAsyncThunk(
  'IntegrationProducts/providerIntegrationUpsert',
  async (provider: IProviderIntegration, thunkAPI) => {
    const requestData = IntegrationProductsTranslator.toProviderIntegrationUpsertRequest(provider)
    return await thunkAPI.extra.portAdminApi().providerIntegrationUpsert(requestData)
  },
);

export const providerIntegrationSaveState = createAppAsyncThunk(
  'IntegrationProducts/providerIntegrationSaveState',
  async (syncState: ProviderSyncState, thunkAPI) => {
    await thunkAPI.extra.portAdminApi().providerIntegrationSaveState({
      syncState,
    });
    return syncState;
  },
);

export const integrationProductsSlice = createSlice({
  name: 'IntegrationProducts',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      {
        payload,
      }: PayloadAction<'getProviderNetworkStatus' | 'changeSyncStateNetworkStatus' | 'upsertProviderIntegrationNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(providerIntegrationGetState.pending, state => {
      state.getProviderNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(providerIntegrationGetState.rejected, state => {
      state.getProviderNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(providerIntegrationGetState.fulfilled, (state, action) => {
      state.syncState = action.payload.syncState;
      state.provider = action.payload.provider;
      state.lastSyncedAt = action.payload.lastSyncedAt;
    })

    builder.addCase(providerIntegrationUpsert.pending, state => {
      state.integrationUpserterrorText = '',
      state.upsertProviderIntegrationNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(providerIntegrationUpsert.rejected, (state, action) => {
      state.integrationUpserterrorText = action.error?.message?.length > 0 ? action.error.message : 'providerIntegrationUpsert error';
      state.upsertProviderIntegrationNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(providerIntegrationUpsert.fulfilled, state => {
      state.upsertProviderIntegrationNetworkStatus = NetworkStatus.Done;
      state.getProviderNetworkStatus = NetworkStatus.None;
    })

    builder.addCase(providerIntegrationSaveState.pending, state => {
      state.changeSyncStateNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(providerIntegrationSaveState.rejected, state => {
      state.changeSyncStateNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(providerIntegrationSaveState.fulfilled, state => {
      state.changeSyncStateNetworkStatus = NetworkStatus.Done;
      state.getProviderNetworkStatus = NetworkStatus.None;
    })
  }
});

export const { resetNetworkStatus } = integrationProductsSlice.actions;

export const integrationProductsState = (state: RootState) => state[integrationProductsSlice.name];

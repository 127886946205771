import {IRuleFn} from '../../utils/theme/fela.interface';
import {padding} from '../../utils/applyCSSProperty ';
import {createCachedStyle} from '../../utils/theme/createCachedStyle';

export interface IModalChooseIntegrationServiceKeys {
  modalInner: {};
  header: {};
  cardTitle: {};
  cardWrapper: {};
}

interface IModalChooseIntegrationServiceProps {
  headerHeight: number;
}

export type IModalChooseIntegrationService = IRuleFn<IModalChooseIntegrationServiceKeys, IModalChooseIntegrationServiceProps>;

const ModalChooseIntegrationServiceRuleFn: IModalChooseIntegrationService = props => {
  const {  sidePadding,  } = props.theme;
  const { headerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      ...padding(0, sidePadding, 58),
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    cardTitle: {
      marginBottom: 4,
    },
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    }
  };
};

export const ModalChooseIntegrationServiceRules = createCachedStyle(ModalChooseIntegrationServiceRuleFn);

import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IUploadedProductsPageKeys {
  header: {};
  container: {};
  span: {};
  productsWrapper: {};
}

export type IUploadedProductsPage = IRuleFn<IUploadedProductsPageKeys>;

const UploadedProductsPageRuleFn: IUploadedProductsPage = props => {
  const {colorBlack} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16), 
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    container: {
      height: '100%',
      overflow: 'hidden',
    },
    span: {
      color: colorBlack,
      opacity: 0.5,
    },
    productsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      paddingBottom: 8,
    },
  };
};

export const UploadedProductsPageRules = createCachedStyle(UploadedProductsPageRuleFn);

import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IProductsIntegrationPageKeys {
  header: {};
  container: {};
  integrationStatus: {};
  infoWrapper: {};
  contentWrapper: {};
}

export type IProductsIntegrationPage = IRuleFn<IProductsIntegrationPageKeys>;

const ProductsIntegrationPageRuleFn: IProductsIntegrationPage = () => {
  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    container: {
      height: '100%',
    },
    integrationStatus: {
      marginTop: 10,
      marginBottom: 58,
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 12,
      ...padding(8, 0, 20),
    },
    contentWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  };
};

export const ProductsIntegrationPageRules = createCachedStyle(ProductsIntegrationPageRuleFn);

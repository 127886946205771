import { CatalogImportResponse } from '@teleport/schemas-protobuf';
import { ICategory, ISubCategory } from 'src/types/categories';

export const UploadedProductsTranslator = {
  fromCatalogImportResponse(response: CatalogImportResponse) {
    const { products } = response;
    return products.map(product => {
      // todo support all fields
      const { uuid, title, price, categories, imageUrls } = product;
      let category: ICategory = undefined;
      let subCategory: ISubCategory = undefined;

      categories.forEach(el => {
        if (el.type.case === 'rootCategory') {
          category = { title: el.type.value.title, uuid: el.type.value.uuid };
        } else {
          subCategory = {
            categoryId: el.type.value.categoryUuid,
            title: el.type.value.title,
            uuid: el.type.value.uuid,
          };
        }
      });

      return {
        id: uuid,
        productName: title,
        imageUrls: imageUrls?.length ? imageUrls : [],
        price: {
          amount: Number(price.amount),
          discountEnabled: price.discountEnabled,
          discountInPercent: Number(price.discountInPercent),
          oldAmount: Number(price.oldAmount),
        },
        category,
        subCategory,
        enabled: product.enabled,
      };
    });
  }
}

import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface ICardInfoBtnRuleKeys {
  cardWrapper: {};
  card: {};
  icon: {};
  title: {};
}

interface ICardInfoBtnRuleProps {
  disabled?: boolean;
  hasDisabledMessage?: boolean;
  isActive: boolean;
  extend: Partial<ICardInfoBtnRuleKeys>;
}

export type ICardInfoBtnRules = IRuleFn<ICardInfoBtnRuleKeys, ICardInfoBtnRuleProps>;

const CardInfoBtnFn: ICardInfoBtnRules = props => {
  const {
    colorWhite,
    colorRed,
    colorRgbWhite,
    background,
    colorBlack,
    transitionDuration,
  } = props.theme;
  const { disabled, isActive } = props;

  return {
    cardWrapper: {
      pointerEvents: disabled ? 'none' : 'auto',
      backgroundColor: background,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...padding(16),
      gap: 12,
      '& svg': {
        fill: colorBlack,
      },
      border: `1px solid ${isActive ? colorBlack : background}`,
      transition: transitionDuration,
    },

    disabledMessage: {
      ...padding(4, 16),
      color: colorRed,
      opacity: 1,
    },

    card: {
      position: 'relative',
      display: 'block',
      ...padding(16),
      borderRadius: 12,
      backgroundColor: colorWhite,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'transparent',

      ':before': {
        content: '\'\'',
        display: disabled ? 'block' : 'none',
        position: 'absolute',
        inset: -1,
        borderRadius: 12,
        backgroundColor: `rgba(${colorRgbWhite}, 0.5)`,
      },

      '& input': {
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
      },
    },
    title: {},
    icon: {
      flexShrink: 0,
    },
  };
};

export const CardInfoBtnRules = createCachedStyle(CardInfoBtnFn);
